<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 content__header">
                <div class="content__header-title">
                    Vestigingen
                </div>
                <a  href="establishments/create" class="content__header-btn">Vestiging toevoegen</a>
            </div>

            <div class="offset-lg-7 col-lg-2 offset-md-4 col-md-4 col-sm-6 col-12 content__right">
                <div class="content__logo">
                    <img src="/images/logo.svg" alt="FoodLyft logo">
                </div>
                <div class="content__export">
                    <button class="btn-export--excel">
                        Export to Excel
                        <img src="/images/excel.svg" />
                    </button>
                </div>
            </div>
        </div>
        <div class="row establishment-table__wrapper">
            <table class="establishment-table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Restaurant</th>
                    <th>Adres</th>
                    <th>Huisnummer,<br>
                        letter (A)</th>
                    <th>Postcode</th>
                    <th>Stad</th>
                </tr>
                </thead>
                <tbody>

                <tr :class="{ grey: !e.active }" v-for="(e,i) of establishments" :key="i">
                    <td>{{ e.id }}</td>
                    <td>{{ e.name }}</td>
                    <td>{{ e.street }}</td>
                    <td>{{ e.house_number }}</td>
                    <td>{{ e.postal_code }}</td>
                    <td>{{ e.city }}</td>
                    <td>
                        <a href="#" class="bekijken-edit">Bekijken
                            <svg  x="0px" y="0px"
                                 viewBox="0 0 14.5 9.5"  xml:space="preserve">
                                 <g>
                                    <g>
                                        <path class="st0" d="M7.25,2.38c-1.29,0-2.33,1.06-2.33,2.38s1.05,2.38,2.33,2.38c1.29,0,2.33-1.06,2.33-2.38S8.54,2.38,7.25,2.38
                                            z M7.07,4.05c-0.29,0-0.53,0.25-0.53,0.54H5.77c0.02-0.74,0.6-1.33,1.3-1.33V4.05z M14.34,4.26C13.55,3.26,10.73,0,7.25,0
                                            S0.96,3.26,0.17,4.26c-0.23,0.28-0.23,0.69,0,0.98c0.79,1,3.61,4.26,7.08,4.26s6.29-3.26,7.08-4.26
                                            C14.56,4.96,14.56,4.55,14.34,4.26z M7.25,8.19c-1.87,0-3.38-1.54-3.38-3.44c0-1.9,1.51-3.44,3.38-3.44
                                            c1.87,0,3.38,1.54,3.38,3.44C10.63,6.65,9.12,8.19,7.25,8.19z"/>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </td>
                    <td>
                        <a :href="`establishments/`+e.id+`/edit`" class="bewerken-edit">Bewerken
                            <svg version="1.1" x="0px" y="0px"
                                 viewBox="0 0 10.99 11"  xml:space="preserve">
                                    <g>
                                        <g>
                                            <path class="st0" d="M1.56,6.7l2.73,2.72l5.16-5.16L6.72,1.55L1.56,6.7z M10.84,2.19L8.82,0.15c-0.2-0.2-0.51-0.2-0.7-0.01
                                                c0,0,0,0-0.01,0.01L7.22,1.04l2.73,2.74l0.89-0.88C11.04,2.69,11.04,2.38,10.84,2.19z M0.03,10.33c-0.1,0.26,0.03,0.54,0.29,0.64
                                                c0.11,0.04,0.24,0.04,0.36,0l3.03-1.14L1.16,7.3L0.03,10.33z"/>
                                        </g>
                                    </g>
                                </svg>
                        </a>
                    </td>
                </tr>

                <tr v-show="!loaders.establishments">
                    <td  colspan="6">
                        <div style="margin: 0 auto; width: 7rem; height: 7rem;" class="d-flex justify-content-center spinner-border text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>

        <div style="cursor: pointer" class="d-flex justify-content-center mt-5">


            <button :disabled="count >= max_pagination_count" @click="showMore" >
                Laat meer zien
            </button>
        </div>

        <div class="row footer-text">
            Pagina 1 van 0, 50 rijen zichtbaar van 0 totaal
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            establishments: Object,

            //loaders
            loaders: {
                establishments: false
            },

            //pagination
            pagination_count: Number,
            count: Number,
            max_pagination_count: Number,
        }
    },

    methods: {
        showMore() {
            axios.post('/api/v1/insert-order/establishment/scan', {
                count: this.count+=this.pagination_count
            })
                .then(r => {
                    this.establishments = r.data.establishments;
                });
        }
    },

    mounted() {
        axios.post('/api/v1/insert-order/establishment/scan')
            .then(r => {
                this.establishments = r.data.establishments;
                //set pagination values
                this.pagination_count = this.count = r.data.count;
                this.max_pagination_count = r.data.max_pagination_count;
                //set pagination values

                this.loaders.establishments = true;

            });
    }
}
</script>
