
var sidenav = document.querySelector('.sidenav');
var sidenavBtn = document.querySelector('.sidenav__header-btn');
var sidenavHeader = document.querySelector('.sidenav__header');
var lastScrollTop = 0;
try {
    sidenavBtn.addEventListener('click', function() {
        if(sidenav.classList.contains('sidenav--open')) {
            sidenav.classList.remove('sidenav--open');
            sidenavBtn.classList.remove('sidenav__header-btn--active');
            if(!sidenavHeader.classList.contains('sidenav__header--hide')) {
                sidenavHeader.classList.add('sidenav__header--hide')
            }
        }
        else {
            sidenav.classList.add('sidenav--open')
            sidenavBtn.classList.add('sidenav__header-btn--active');
            if(sidenavHeader.classList.contains('sidenav__header--hide')) {
                sidenavHeader.classList.remove('sidenav__header--hide')
            }
        }
    })
} catch (e) {
    console.log(e);
}

function toggleHeader() {
    try {
      var st = window.pageYOffset || document.documentElement.scrollTop;
  
      if (st > lastScrollTop) {
        if(!sidenav.classList.contains('sidenav--open')) {
            sidenavHeader.classList.add('sidenav__header--hide');
        }
      } else {
        sidenavHeader.classList.remove('sidenav__header--hide');
      }
  
      lastScrollTop = st <= 0 ? 0 : st;
    } catch (e) {
      console.log(e);
    }
  }

window.onscroll = function () {
    toggleHeader();
};
