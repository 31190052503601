<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12 content__header">
                <div class="content__header-title">
                    Locaties
                </div>
                <a  href="location/create" class="content__header-btn">Gebruiker toevoegen</a>
            </div>

            <div class="offset-lg-7 col-lg-2 offset-md-4 col-md-4 col-sm-6 col-12 content__right">
                <div class="content__logo">
                    <img src="/images/logo.svg" alt="FoodLyft logo">
                </div>
                <div class="content__export">
                    <button class="btn-export--excel">
                        Export to Excel
                        <img src="/images/excel.svg" />
                    </button>
                </div>
            </div>
        </div>
        <div class="row gebruikers-table__wrapper">
            <table class="gebruikers-table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Naam</th>
                </tr>
                </thead>
                <tbody>
                    <tr  v-for="(u,i) of users" :key="i">
                        <td>{{ u.id }}</td>
                        <td>{{ u.name }}</td>
                        <td>
                            <a :href="`location/`+u.id+`/edit`" class="bewerken-edit">Bewerken
                                <svg version="1.1" x="0px" y="0px"
                                     viewBox="0 0 10.99 11"  xml:space="preserve">
                                    <g>
                                        <g>
                                            <path class="st0" d="M1.56,6.7l2.73,2.72l5.16-5.16L6.72,1.55L1.56,6.7z M10.84,2.19L8.82,0.15c-0.2-0.2-0.51-0.2-0.7-0.01
                                                c0,0,0,0-0.01,0.01L7.22,1.04l2.73,2.74l0.89-0.88C11.04,2.69,11.04,2.38,10.84,2.19z M0.03,10.33c-0.1,0.26,0.03,0.54,0.29,0.64
                                                c0.11,0.04,0.24,0.04,0.36,0l3.03-1.14L1.16,7.3L0.03,10.33z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>

                            <a style="color: #d76a6a;padding-top: 10px;" :href="`location/`+u.id+`/delete`" class="bewerken-edit">Delete
                            </a>
                        </td>
                    </tr>

                    <tr v-show="!loaders.users">
                        <td  colspan="6">
                            <div style="margin: 0 auto; width: 7rem; height: 7rem;" class="d-flex justify-content-center spinner-border text-dark" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div style="cursor: pointer" class="d-flex justify-content-center mt-5">


            <button :disabled="count >= max_pagination_count" @click="showMore" >
                Laat meer zien
            </button>
        </div>

        <div class="row footer-text">
            Pagina 1 van 0, 50 rijen zichtbaar van 0 totaal
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                users: Object,

                //loaders
                loaders: {
                    users: false
                },

                //pagination
                pagination_count: Number,
                count: Number,
                max_pagination_count: Number,
            }
        },

        methods: {
          showMore() {
              axios.post('/api/v1/insert-order/location/scan', {
                  count: this.count+=this.pagination_count
              })
                  .then(r => {
                      this.users = r.data.users;
                  });
          }
        },

        mounted() {
            axios.post('/api/v1/insert-order/location/scan')
                .then(r => {
                    this.users = r.data.users;
                    //set pagination values
                    this.pagination_count = this.count = r.data.count;
                    this.max_pagination_count = r.data.max_pagination_count;
                    //set pagination values

                    this.loaders.users = true;
                });
        }
    }
</script>
