<template>
    <div></div>
</template>

<script>
export default {
    name: "CheckAuth",

    props: ['role'],
    data() {
        return {
            interval: null,
            intervalPosition: null,

            couriers: [],
        }
    },

    created () {
        this.interval = setInterval(this.checkAuth, 10000)
        this.intervalPosition = setInterval(this.checkPosition, 50000)
    },

    beforeDestroy () {
        clearInterval(this.interval)
        clearInterval(this.intervalPosition)
    },

    methods: {

        checkAuth() {
            if(this.role == 2) {
                axios.post('/api/v1/checkCourierAuth').then(r=>{
                    if(r.data.success) {
                        if(r.data.data.length > 0) {
                            r.data.data.forEach(e=>{
                                alert(`Courier ` + e.name + ` is logged out!`);
                            });
                        }
                    }
                });
            }
        },

        checkPosition() {
            if(this.role == 2) {
                axios.post('/api/v1/checkCourierPosition').then(r=>{
                    if(r.data.success) {
                        if(r.data.data.length > 0) {
                            r.data.data.forEach(e=>{
                                alert(`Courier ` + e.name + ` dont change position!`);
                            });
                        }
                    }
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
