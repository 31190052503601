<template>
    <div class="container-fluid">
<!--        <div class="row">-->
<!--            <div class="col-lg-2 col-12 content__header col-md-6 col-sm-12">-->
<!--                <div class="content__header-title">-->

<!--                </div>-->
<!--                <a href="/insert-order/order/create" class="content__header-btn">Bestelling toevoegen</a>-->
<!--            </div>-->
<!--            <div class="col-lg-2 col-12 col-sm-12 content__filter">-->
<!--                <div @click="setStatus(1)"-->
<!--                     :class="`content__filter-item content__filter-item&#45;&#45;nieuw ` + (selected_status.indexOf(1) != -1 ? 'content__filter-item&#45;&#45;active' : '')">-->
<!--                    <div class="content__filter-circle"></div>-->
<!--                    nieuw-->
<!--                </div>-->

<!--                <div @click="setStatus(2)"-->
<!--                     :class="`content__filter-item content__filter-item&#45;&#45;aangesteld ` + (selected_status.indexOf(2) != -1 ? 'content__filter-item&#45;&#45;active' : '')">-->
<!--                    <div class="content__filter-circle"></div>-->
<!--                    koerier aangesteld-->
<!--                </div>-->
<!--                <div @click="setStatus(3)"-->
<!--                     :class="`content__filter-item content__filter-item&#45;&#45;namdeorde ` + (selected_status.indexOf(3) != -1 ? 'content__filter-item&#45;&#45;active' : '')">-->
<!--                    <div class="content__filter-circle"></div>-->
<!--                    koerier nam de orde-->
<!--                </div>-->
<!--                <div @click="setStatus(4)"-->
<!--                     :class="`content__filter-item content__filter-item&#45;&#45;geleverd ` + (selected_status.indexOf(4) != -1 ? 'content__filter-item&#45;&#45;active' : '')">-->
<!--                    <div class="content__filter-circle"></div>-->
<!--                    bestelling geleverd-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-12 col-md-6 col-sm-12 content__form">-->
<!--                <div class="row">-->
<!--                    <div class="col-12 col-lg-6 col-md-12 col-sm-12">-->
<!--                        <div class="form-group form-group&#45;&#45;datepicker">-->
<!--                            &lt;!&ndash;<input type="date" id="datum-vanaf" class="form-input form-input&#45;&#45;datepicker" />&ndash;&gt;-->
<!--                            <label class="form-label" for="datum-vanaf">Datum vanaf</label>-->
<!--                            <datetime placeholder="Datum" value-zone="Europe/Amsterdam" zone="Europe/Amsterdam"-->
<!--                                      input-id="datum-vanaf" input-class="form-input" auto type="datetime"-->
<!--                                      v-model="search_date_from">-->

<!--                            </datetime>-->
<!--                            &lt;!&ndash;<span class="error-label">Already required</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class=" col-lg-6 col-md-12 col-sm-12 col-12">-->
<!--                        <div class="form-group form-group&#45;&#45;datepicker">-->
<!--                            &lt;!&ndash;<input type="date" id="datum-tot" class="form-input form-input&#45;&#45;datepicker" />&ndash;&gt;-->
<!--                            <label class="form-label" for="datum-tot">Datum tot</label>-->
<!--                            <datetime placeholder="Datum" value-zone="Europe/Amsterdam" zone="Europe/Amsterdam"-->
<!--                                      input-id="datum-tot" input-class="form-input" auto type="datetime"-->
<!--                                      v-model="search_date_to"></datetime>-->
<!--                            &lt;!&ndash;<span class="error-label">Already required</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 ">-->
<!--                        <div class="form-group form-group&#45;&#45;search">-->
<!--                            <input id="search_word" v-model="search_word" type="text"-->
<!--                                   class="form-input form-input&#45;&#45;search" placeholder=" "/>-->
<!--                            <label class="form-label" for="search_word">Zoeken op trefwoord</label>-->
<!--                            &lt;!&ndash;<span class="error-label">Already required</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-3 col-12 col-sm-12 col-md-6 content__controls">-->
<!--                <div class="row">-->
<!--                    <div class="col-lg-12 col-12 col-md-12 content__controls-wrapper">-->
<!--                        <button class="content__controls-btn content__controls-btn&#45;&#45;circle">-->
<!--                            <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"-->
<!--                                 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
<!--                                 viewBox="0 0 5.008 8.506" enable-background="new 0 0 5.008 8.506" xml:space="preserve">-->
<!--                                    <g>-->
<!--                                    <g>-->
<!--                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.774,3.628L1.368,0.233c-0.312-0.311-0.817-0.311-1.129,0L0.234,0.238-->
<!--                                            c-0.312,0.311-0.312,0.814,0,1.125l2.9,2.89l-2.9,2.89c-0.312,0.311-0.312,0.814,0,1.125l0.005,0.005-->
<!--                                            c0.312,0.311,0.817,0.311,1.129,0l3.407-3.395c0.172-0.172,0.24-0.401,0.221-0.625C5.014,4.029,4.947,3.799,4.774,3.628z"/>-->
<!--                                    </g>-->
<!--                                    </g>-->
<!--                                </svg>-->
<!--                        </button>-->
<!--                        <button class="content__controls-btn content__controls-btn&#45;&#45;vandaag" @click="setCurrentDate">-->
<!--                            Vandaag-->
<!--                        </button>-->
<!--                    </div>-->
<!--                    <div class="col-lg-12 col-12 col-sm-12 col-md-12">-->
<!--                        <div v-if="!user_establishment" class="form-group form-group&#45;&#45;select">-->
<!--                            <select v-model="filter_establishments" id="search_establishment" class="form-select">-->
<!--                                <option value="">&#45;&#45; Kiezen &#45;&#45;</option>-->
<!--                                <option v-for="(e, i) of establishments"-->
<!--                                        :key="i"-->
<!--                                        :value="e.id">-->
<!--                                    {{ e.name }}-->
<!--                                </option>-->
<!--                            </select>-->
<!--                            <label for="search_establishment" class="form-label">Filteren op restaurant</label>-->
<!--                            &lt;!&ndash;<span class="error-label">Already required</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-2 col-12 col-sm-12 col-md-6 content__right">-->
<!--                <div class="content__logo content__logo&#45;&#45;bestellingen">-->
<!--                    <img src="/images/logo.svg" alt="FoodLyft logo">-->
<!--                </div>-->
<!--                <div class="content__right-wrapper">-->

<!--                    <div v-if="user_role == 1" class="form-group form-group-range">-->
<!--                        <label for="" class="form-label-range">Restaurant load:</label>-->
<!--                        <div class="range-wrapper">-->
<!--                            <div class="range-icons">-->
<!--                                <div class="range-icon range-icon&#45;&#45;middle">-->
<!--                                    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"-->
<!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
<!--                                         viewBox="0 0 10.825 14.5" enable-background="new 0 0 10.825 14.5"-->
<!--                                         xml:space="preserve">-->
<!--                                        <g>-->
<!--                                        <g>-->
<!--                                        <path d="M2.776,3.508v0.741H8.05V3.508c0.503-0.261,0.833-0.791,0.833-1.383c0-0.859-0.685-1.558-1.527-1.558-->
<!--                                        c-0.191,0-0.378,0.037-0.553,0.108C6.465,0.255,5.955,0,5.413,0s-1.052,0.255-1.39,0.674C3.847,0.603,3.661,0.566,3.47,0.566-->
<!--                                        c-0.842,0-1.527,0.699-1.527,1.558C1.943,2.716,2.273,3.246,2.776,3.508z M6.662,9.392C6.274,9.545,5.853,9.629,5.413,9.629-->
<!--                                        c-0.44,0-0.861-0.084-1.249-0.237v0.379c0,0.704,0.559,1.274,1.249,1.274s1.249-0.57,1.249-1.274V9.392z M2.776,6.089-->
<!--                                        c0,1.484,1.183,2.69,2.637,2.69S8.05,7.572,8.05,6.089V5.098H2.776V6.089z M3.331,9.771V9.629H3.192-->
<!--                                        C1.429,9.629,0,11.087,0,12.886v1.189C0,14.31,0.186,14.5,0.416,14.5h1.527v-1.048c0-0.234,0.186-0.425,0.416-0.425-->
<!--                                        c0.23,0,0.416,0.19,0.416,0.425V14.5h3.053v-2.648c-0.135,0.028-0.274,0.043-0.416,0.043C4.265,11.895,3.331,10.942,3.331,9.771z-->
<!--                                            M4.858,13.594c-0.23,0-0.416-0.19-0.416-0.425c0-0.235,0.186-0.425,0.416-0.425c0.23,0,0.416,0.19,0.416,0.425-->
<!--                                        C5.274,13.404,5.088,13.594,4.858,13.594z M7.633,9.629H7.495v0.142c0,0.693-0.327,1.31-0.833,1.698V14.5H8.05v-1.048-->
<!--                                        c0-0.234,0.187-0.425,0.416-0.425c0.23,0,0.416,0.19,0.416,0.425V14.5h1.527c0.23,0,0.416-0.19,0.416-0.425v-1.189-->
<!--                                        C10.825,11.087,9.396,9.629,7.633,9.629z"/>-->
<!--                                        </g>-->
<!--                                        </g>-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                                <div class="range-icon range-icon&#45;&#45;low">-->
<!--                                    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"-->
<!--                                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"-->
<!--                                         viewBox="0 0 10.825 14.5" enable-background="new 0 0 10.825 14.5"-->
<!--                                         xml:space="preserve">-->
<!--                                        <g>-->
<!--                                        <g>-->
<!--                                        <path d="M2.776,3.508v0.741H8.05V3.508c0.503-0.261,0.833-0.791,0.833-1.383c0-0.859-0.685-1.558-1.527-1.558-->
<!--                                        c-0.191,0-0.378,0.037-0.553,0.108C6.465,0.255,5.955,0,5.413,0s-1.052,0.255-1.39,0.674C3.847,0.603,3.661,0.566,3.47,0.566-->
<!--                                        c-0.842,0-1.527,0.699-1.527,1.558C1.943,2.716,2.273,3.246,2.776,3.508z M6.662,9.392C6.274,9.545,5.853,9.629,5.413,9.629-->
<!--                                        c-0.44,0-0.861-0.084-1.249-0.237v0.379c0,0.704,0.559,1.274,1.249,1.274s1.249-0.57,1.249-1.274V9.392z M2.776,6.089-->
<!--                                        c0,1.484,1.183,2.69,2.637,2.69S8.05,7.572,8.05,6.089V5.098H2.776V6.089z M3.331,9.771V9.629H3.192-->
<!--                                        C1.429,9.629,0,11.087,0,12.886v1.189C0,14.31,0.186,14.5,0.416,14.5h1.527v-1.048c0-0.234,0.186-0.425,0.416-0.425-->
<!--                                        c0.23,0,0.416,0.19,0.416,0.425V14.5h3.053v-2.648c-0.135,0.028-0.274,0.043-0.416,0.043C4.265,11.895,3.331,10.942,3.331,9.771z-->
<!--                                            M4.858,13.594c-0.23,0-0.416-0.19-0.416-0.425c0-0.235,0.186-0.425,0.416-0.425c0.23,0,0.416,0.19,0.416,0.425-->
<!--                                        C5.274,13.404,5.088,13.594,4.858,13.594z M7.633,9.629H7.495v0.142c0,0.693-0.327,1.31-0.833,1.698V14.5H8.05v-1.048-->
<!--                                        c0-0.234,0.187-0.425,0.416-0.425c0.23,0,0.416,0.19,0.416,0.425V14.5h1.527c0.23,0,0.416-0.19,0.416-0.425v-1.189-->
<!--                                        C10.825,11.087,9.396,9.629,7.633,9.629z"/>-->
<!--                                        </g>-->
<!--                                        </g>-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="range-control">-->
<!--                                <input v-model="loadStatus" type="range" class="range-input" min="1" max="2" step="1">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div style="position:relative;width: 100%"-->
<!--                         class="content__export content__export&#45;&#45;bestellingen tooltiper">-->
<!--                        <div style="justify-content: flex-end;display: flex;" class="cc">-->
<!--                            <export-excel-->
<!--                                data-toggle="tooltip"-->
<!--                                title="Tooltip on top"-->
<!--                                style="cursor: pointer;"-->
<!--                                class="btn-export&#45;&#45;excel tooltipcursor"-->
<!--                                role="button"-->
<!--                                :fetch="exportGenerate"-->
<!--                                name="orders.xls">-->
<!--                                <img src="/images/excel.svg"/>-->
<!--                            </export-excel>-->

<!--                            <span class="tooltiptext">Generate to Excel</span>-->
<!--                        </div>-->

<!--                        <div style="z-index:1000;position: absolute;top: 28px;right:0;left:auto;width: 150px;"-->
<!--                             v-if="export_is_generated" class="export_dropdown">-->
<!--                            <export-excel-->
<!--                                style="cursor: pointer"-->
<!--                                class="btn-export&#45;&#45;excel"-->
<!--                                role="button"-->
<!--                                :fetch="exportData"-->
<!--                                :before-finish="exportFinish"-->
<!--                                name="orders.xls">-->
<!--                                >-->
<!--                                Download-->
<!--                            </export-excel>-->
<!--                        </div>-->
<!--                    </div>-->


<!--                    <div v-if="user_role == 1" style="width: 50px;cursor: pointer;">-->
<!--                        <div>-->
<!--                            <svg @click="changeSoundNotify" :fill="soundNotify == 1 ? 'red': 'black'" version="1.1"-->
<!--                                 id="Layer_1" xmlns="http://www.w3.org/2000/svg"-->
<!--                                 xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--                                 x="0px" y="0px"-->
<!--                                 viewBox="0 0 459.334 459.334" style="enable-background:new 0 0 459.334 459.334;"-->
<!--                                 xml:space="preserve">-->
<!--                                <g>-->
<!--                                    <g>-->
<!--                                        <g>-->
<!--                                            <path d="M175.216,404.514c-0.001,0.12-0.009,0.239-0.009,0.359c0,30.078,24.383,54.461,54.461,54.461-->
<!--                                                s54.461-24.383,54.461-54.461c0-0.12-0.008-0.239-0.009-0.359H175.216z"/>-->
<!--                                            <path d="M403.549,336.438l-49.015-72.002c0-22.041,0-75.898,0-89.83c0-60.581-43.144-111.079-100.381-122.459V24.485-->
<!--                                                C254.152,10.963,243.19,0,229.667,0s-24.485,10.963-24.485,24.485v27.663c-57.237,11.381-100.381,61.879-100.381,122.459-->
<!--                                                c0,23.716,0,76.084,0,89.83l-49.015,72.002c-5.163,7.584-5.709,17.401-1.419,25.511c4.29,8.11,12.712,13.182,21.887,13.182-->
<!--                                                H383.08c9.175,0,17.597-5.073,21.887-13.182C409.258,353.839,408.711,344.022,403.549,336.438z"/>-->
<!--                                        </g>-->
<!--                                    </g>-->
<!--                                </g>-->
<!--                            </svg>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->

<!--            </div>-->
<!--        </div>-->

        <div class="row bestellingen-table__wrapper">
            <table class="bestellingen-table">
                <thead>
                <tr>
                    <th class="centered">Ref #</th>
                    <th>Besteltijd</th>
                    <th>Afleveradres</th>
                    <th>Winkelwagen</th>

                    <th class="centered">Prijs</th>

                    <th class="centered">Tijd van levering</th>
                </tr>
                </thead>
                <tbody>

                <!--orders-->
                <tr v-if="loaders.order" v-for="(o,i) of orders" :key="i"  :class="`status_`+o.status">

                    <td class="centered poppins-bold">
                        {{ o.ref }}
                    </td>
                    <td>
                        <div>{{ o.created_at_date }}</div>
                    </td>
                    <!--                    <td class="centered">-->
                    <!--                        <div :class="`table-circle table-circle&#45;&#45;` +  o.status"></div>-->
                    <!--                    </td>-->

                    <td v-if="o.pickup"><strong style="color:#d23e97;font-weight: 800 ;">Pickup</strong> | {{ o.client.name }} {{ o.client.phone}}</td>
                    <td v-else class="poppins-bold">
                        {{ o.client.street }}{{ o.client.house_number ? ' ' + o.client.house_number : '' }},
                        {{ o.client.postal_code }} | {{ o.client.name }} {{ o.client.phone}}
                    </td>

                    <td>
                        <collapse :selected="false">
                            <div slot="collapse-header">
                                show
                            </div>
                            <div slot="collapse-body">

                                <small style="display: block" v-for="(c, index) of o.cart" :key="index">{{ c.quantity }} X {{ c.title }} - &euro; {{ c.total}}</small>
                                <hr>
                                <small>{{ o.client.description}}</small>
                            </div>
                        </collapse>
                    </td>


                    <td class="centered">{{ o.price }}</td>


                    <td class="centered">
                        <img v-if="o.asap" src="/images/bike.svg"/>
                        <div v-else>{{ o.time }}</div>
                    </td>
                    <td class="d-flex flex-column centered">
                        <a href="#" v-if="o.status === 'nieuw'" @click.prevent="changeStatus(o.id)">accept</a>
                        <a href="#" v-if="o.status === 'namdeorde'" @click.prevent="changeStatus(o.id)">close</a>
                        <div v-if="o.is_new">
                            {{ soundNotification(o.id) }}
                        </div>
                        <!--button status-->
                        <!-- <a style="cursor: pointer" v-on:click.prevent.stop="countDown(o.id)" v-if="user_role == 2 && o.status === 'nieuw'" >dispatch</a> -->
                        <!--                        <a style="cursor: pointer" v-on:click.prevent.stop="changeStatus(o.id)" v-if="user_role == 2 && o.status == 'aangesteld'">close</a>-->
<!--                        <a style="cursor: pointer" v-on:click.prevent.stop="cancelOrder(o.id)"-->
<!--                           v-if="user_role == 2 && o.status == 'aangesteld'">cancel</a>-->
<!--                        <a v-if="user_role == 2 && o.status !== 'geleverd'" :href="`order/`+o.id+`/edit`">edit</a>-->
<!--                        <a style="cursor: pointer" v-on:click.prevent.stop="copyOrderId = o.id" v-if="user_role == 2">copy</a>-->
<!--                        <a style="cursor: pointer" v-on:click.prevent.stop="deleteOrderId = o.id"-->
<!--                           v-if="user_role == 2 && (o.status === 'nieuw' || o.status === 'aangesteld')">delete</a>-->
                        <!--                        <a style="cursor: pointer" v-on:click.prevent.stop="changeStatus(o.id)" v-if="user_role == 2 && o.status == 'aangesteld'">close</a>-->
                        <!--button status-->
                    </td>
                </tr>
                <tr v-show="!loaders.order">
                    <td colspan="9">
                        <div style="margin: 0 auto; width: 7rem; height: 7rem;"
                             class="d-flex justify-content-center spinner-border text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <!--orders-->

                </tbody>
            </table>
        </div>

        <!-- <div v-show="countdown_fields" v-on:click="hideOverlay" class="overlay">
            <div class="countdown">
                <div class="mb-3">Countdown:</div>
                <div class="form-group">
                    <button @click="changeStatus(save_countdown_id, true, 5)" class="content__header-btn">5 min</button>
                    <button @click="changeStatus(save_countdown_id, true, 10)" class="content__header-btn">10 min</button>
                    <button @click="changeStatus(save_countdown_id, true, 16)" class="content__header-btn">16 min</button>
                </div>
            </div>
        </div> -->

        <div v-show="restaurantDelayModal.status && restaurantDelayModal.result >= 3" v-on:click="hideOverlay" class="overlay">
            <div class="countdown">
                <div class="mb-3">Previous day average wait time was <strong style="color: red;">{{  restaurantDelayModal.result }}</strong> minutes</div>
                <div class="mb-3">Would you like to change the standard cooking time?</div>
                <div class="form-group">
                    <input type="number" v-model="restaurantDelayModal.cookingTime" step="1" value="0" min="0" placeholder="Minutes" >
                    <button style="background-color: #3fbccd !important;" @click="changeStandardCookingTime" class="content__header-btn">Change</button>
                </div>
            </div>
        </div>

        <div v-show="deleteOrderId" v-on:click="hideOverlay" class="overlay">
            <div class="countdown">
                <div class="mb-3">Are you sure?</div>
                <div class="form-group">
                    <button style="background-color: #ff6042 !important;" @click="deleteOrder(deleteOrderId)"
                            class="content__header-btn">Delete
                    </button>
                </div>
            </div>
        </div>


        <div v-show="copyOrderId" v-on:click="hideOverlay" class="overlay">
            <div class="countdown">
                <div class="mb-3">Are you sure?</div>
                <div class="form-group">
                    <button style="background-color: #3fbccd !important;" @click="copyOrder(copyOrderId)"
                            class="content__header-btn">Copy
                    </button>
                </div>
            </div>
        </div>

        <div style="cursor: pointer" class="d-flex justify-content-center mt-5">


            <button :disabled="count >= max_pagination_count" @click="showMore">
                Laat meer zien
            </button>
        </div>

        <div v-if="countsIsLoaded" class="row footer-text">
            Pagina {{ count_pages > max_pages ? max_pages : count_pages }} van {{ max_pages }},
            {{ count > max_pagination_count ? max_pagination_count : count }} rijen zichtbaar van
            {{ max_pagination_count }} totaal
        </div>
    </div>
</template>

<script>
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import CheckAuth from "../CheckAuth";
import Collapse from 'vue-collapse'


export default {

    props: {
        establishments: false,
        user_establishment: Object,
        user_role: Number,
        date_from: false,
        date_to: false,
    },

    components: {
        Datetime, CheckAuth, Collapse
    },


    data() {
        return {
            //countdown
            countdown_fields: false,
            save_countdown_id: false,

            //deleteOrder
            deleteOrderId: false,
            //copy order
            copyOrderId: false,

            //loaders
            loaders: {
                order: false
            },


            filter_establishments: '',

            search_word: '',

            //date search
            search_date_from: this.date_from,
            search_date_to: this.date_to,
            dataIsLoading: false,
            //date search

            selected_status: [],

            orders: Object,

            pagination_count: Number,
            count: Number,
            max_pagination_count: Number,
            max_pages: Number,
            count_pages: 1,
            countsIsLoaded: false,

            //autoloader
            interval: null,

            countDownDelay: true,


            orders_export_data: [],
            export_is_generated: false,

            loadStatus: this.user_establishment ? this.user_establishment.load_status : 1,

            //sound notify status
            soundNotify: this.user_establishment ? this.user_establishment.sound_notify : false,

            restaurantDelayModal: {
                status: false,
                result: 0,
                cookingTime: 0
            },

        }
    },


    watch: {
        loadStatus(val) {
            axios.post('/api/v1/insert-order/order/loadStatus', {
                establishment_id: this.user_establishment.id,
                status: val
            }).then(r => {
                console.log(r.data)
            });
        },

        search_date_from() {
            if (this.dataIsLoading) {
                this.search(true)
            }

        },

        search_date_to() {
            if (this.dataIsLoading) {
                this.search(true)
            }
        },

        filter_establishments() {
            this.search(true)
        },
        selected_status() {
            this.search(true)
        },
        search_word() {
            this.search(true)
        },


    },

    mounted() {
        axios.post('/api/v1/insert-order/order/scan', {
            search: {
                date_from: this.date_from,
                date_to: this.date_to,
            }
        })
            .then(r => {
                this.orders = r.data.order
                //set pagination values
                this.pagination_count = this.count = r.data.count
                this.max_pagination_count = r.data.max_pagination_count
                this.max_pages = r.data.max_pages;
                //set pagination values
                this.loaders.order = true;
                this.countsIsLoaded = true;
                this.dataIsLoading = true;
            });

        /**
         * получаем моадлку инфы для задержки ресторана
         **/
        // axios.post('/api/v1/check-restaurant-delay')
        //     .then(r => {
        //         this.restaurantDelayModal.status = r.data.showModal;
        //         this.restaurantDelayModal.cookingTime = r.data.standard_cooking;
        //         this.restaurantDelayModal.result = r.data.result;
        //     });
    },

    methods: {

        changeStandardCookingTime() {
            axios.post('/api/v1/change-cooking-time', {
                minutes: this.restaurantDelayModal.cookingTime
            })
                .then(r => {
                    location.href = '/insert-order/order'
                });
        },

        changeSoundNotify() {
            this.soundNotify = !this.soundNotify;

            axios.post('/api/v1/insert-order/order/soundNotify', {
                establishment_id: this.user_establishment.id,
                sound_notify: this.soundNotify
            }).then(r => {
                this.$toasted.show('Sound ' + (this.soundNotify ? 'on' : 'off'), {
                    theme: "bubble",
                    position: "bottom-center",
                    duration: 3000
                })
            });
        },

        copyOrder(order) {
            axios.post('/api/v1/insert-order/order/copy', {
                order_id: order
            })
                .then(r => {
                    this.search();
                    this.copyOrderId = false;

                    this.$toasted.show('Order copied!', {
                        theme: "bubble",
                        position: "bottom-center",
                        duration: 3000
                    })
                });
        },
        deleteOrder(order) {
            axios.post('/api/v1/insert-order/order/delete', {
                order_id: order
            })
                .then(r => {
                    this.search();
                    this.deleteOrderId = false;

                    this.$toasted.show('Order deleted!', {
                        theme: "bubble",
                        position: "bottom-center",
                        duration: 3000
                    })
                });
        },

        displayTime(time) {
            let date_now = Date.now();

            if (date_now <= time) {
                let duration = time - date_now;

                let seconds = Math.floor((duration / 1000) % 60),
                    minutes = Math.floor((duration / (1000 * 60)) % 60);


                if (this.user_role == 1
                    && minutes == 8
                    && this.user_establishment.sound_notify === true
                    && (seconds == 55
                        || seconds == 56
                        || seconds == 57
                        || seconds == 58
                        || seconds == 59
                    )) {
                    let audio = new Audio('https://soundbible.com/mp3/glass_ping-Go445-1207030150.mp3');
                    audio.play();
                }
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;


                // if(minutes < '02') {
                //     return  '<strong style="color:red;font-weight: 800;">' + minutes + ":" + seconds + '</strong>';
                // }

                return minutes + ":" + seconds;
            } else {
                let date_now = Date.now();
                let duration = time - date_now;

                let seconds = Math.floor((duration / 1000) % 60),
                    minutes = Math.floor((duration / (1000 * 60)) % 60);

                let t_seconds = (seconds > -10) ? "0" + seconds : seconds;
                let seconds_to_line = `${t_seconds}`;
                let change_seconds = seconds_to_line.replace('-', '');
                return minutes + ":" + change_seconds;
                //return '<strong style="color:red;font-weight: 800;">' + minutes + ":" + change_seconds + '</strong>';
            }
        },

        soundNotification(id) {
            let audio = new Audio('https://soundbible.com/mp3/glass_ping-Go445-1207030150.mp3');
            audio.play();

            axios.post('/api/v1/insert-order/order/sound-notification-status-change', {
                id
            });
        },

        exportGenerate() {

            this.export_is_generated = !this.export_is_generated;

            if (this.export_is_generated) {
                axios.post('/api/v1/insert-order/order/scan', {
                    establishment_id: this.user_establishment ? this.user_establishment.id : null,
                    search: {
                        word: this.search_word,
                        status: this.selected_status,
                        establishment: this.filter_establishments,
                        date_from: this.search_date_from,
                        date_to: this.search_date_to,
                    },
                    count: 10000
                })
                    .then(r => {
                        this.orders_export_data = r.data.order;
                    });
            } else {
                this.orders_export_data = [];
            }
        },

        exportData() {
            let arr = [];

            for (const [key, value] of Object.entries(this.orders_export_data)) {
                let e = {
                    "Ref": value.ref,
                    "Besteltijd": value.created_at_date,
                    "Restaurant": value.establishment.street + " " + value.establishment.house_number + ", " + value.establishment.postal_code + " | " + value.establishment.name,
                    "Afleveradres": value.client.street + " " + value.client.house_number + ", " + value.client.postal_code + " | " + value.client.name,
                    "Naam koerier": value.courierName,
                    "Prijs": value.price,
                    "Zo snel mogelijk": value.time,
                    "Ophaal tijd": value.time_of_pickup,
                    "Tijd om te bezorgen": value.time_of_delivery,
                };
                arr.push(e)
            }

            return arr;

        },

        exportFinish() {
            this.orders_export_data = [];
            this.export_is_generated = false;
        },

        search(isFilter) {
            axios.post('/api/v1/insert-order/order/scan', {
                search: {
                    word: this.search_word,
                    status: this.selected_status,
                    establishment: this.filter_establishments,
                    date_from: this.search_date_from,
                    date_to: this.search_date_to,
                },
                count: this.count
            })
                .then(r => {
                    this.orders = r.data.order
                    this.count = r.data.count
                    this.max_pagination_count = r.data.max_pagination_count
                    this.max_pages = r.data.max_pages;

                    //hide export
                    if (isFilter) {
                        this.export_is_generated = false;
                        this.orders_export_data = [];
                    }
                });
        },

        hideOverlay(e) {
            if (e.target.classList.contains('overlay')) {
                this.countdown_fields = false;
                this.copyOrderId = false;
                this.deleteOrderId = false;
                this.restaurantDelayModal = false;
            } else {
                return false;
            }
        },

        countDown(id) {
            this.countdown_fields = true;
            this.save_countdown_id = id;
        },

        cancelOrder(id) {
            axios.post('/api/v1/insert-order/order/cancelOrder', {
                id: id,
            }).then((r) => {
                if (r.data.changed) {
                    this.search();
                    this.$toasted.show('Status has been changed!', {
                        theme: "bubble",
                        position: "bottom-center",
                        duration: 3000
                    })
                } else {
                    this.$toasted.show('Can\'t change status', {
                        theme: "bubble",
                        position: "bottom-center",
                        duration: 3000
                    })
                }
            });
        },

        changeStatus(id, countdown = false, time = false) {


            if (countdown) {
                axios.post('/api/v1/insert-order/order/setCountdown', {
                    id: id,
                    time: time
                }).then(() => {
                    axios.post('/api/v1/insert-order/order/changeStatus', {
                        id,
                        countdown: true
                    })
                        .then(r => {
                            axios.post('/api/v1/insert-order/order/scan', {
                                establishment_id: this.user_establishment.id,
                                search: {
                                    status: this.selected_status,
                                    word: this.search_word,
                                    establishment: this.filter_establishments,
                                },
                                count: this.count
                            })
                                .then(r => {
                                    this.orders = r.data.order
                                    //set pagination values
                                    //this.count = r.data.count
                                    this.max_pagination_count = r.data.max_pagination_count
                                    //set pagination values
                                    this.loaders.order = true;

                                    this.countdown_fields = false;
                                    this.save_countdown_id = false;

                                    //hide export
                                    this.export_is_generated = false;
                                    this.orders_export_data = [];


                                    /*if(countdown) {
                                        document.location.reload(true);
                                    } else {*/
                                    this.$toasted.show('Status has been changed!', {
                                        theme: "bubble",
                                        position: "bottom-center",
                                        duration: 3000
                                    })
                                    // }
                                });
                        });

                });


            } else {
                axios.post('/api/v1/insert-order/order/changeStatus', {
                    id
                })
                    .then(r => {
                        axios.post('/api/v1/insert-order/order/scan', {
                            search: {
                                status: this.selected_status,
                                word: this.search_word,
                                establishment: this.filter_establishments,
                                date_from: this.search_date_from,
                                date_to: this.search_date_to,
                            },
                            count: this.count
                        })
                            .then(r => {
                                this.orders = r.data.order
                                //set pagination values
                                //this.count = r.data.count
                                this.max_pagination_count = r.data.max_pagination_count
                                //set pagination values
                                this.loaders.order = true;


                                //hide export
                                this.export_is_generated = false;
                                this.orders_export_data = [];

                                /*if(countdown) {
                                    document.location.reload(true);
                                } else {*/
                                this.$toasted.show('Status has been changed!', {
                                    theme: "bubble",
                                    position: "bottom-center",
                                    duration: 3000
                                })
                                // }
                            });
                    });
            }

        },
        setStatus(status) {
            if (this.selected_status.indexOf(status) != -1) {
                let ar = this.selected_status.filter(i => i !== status);
                this.selected_status = ar;
            } else {
                this.selected_status.push(status)
            }

        },

        showMore() {
            this.count_pages = this.count_pages + 1;

            axios.post('/api/v1/insert-order/order/scan', {
                search: {
                    word: this.search_word,
                    status: this.selected_status,
                    establishment: this.filter_establishments,
                    date_from: this.search_date_from,
                    date_to: this.search_date_to,
                },
                count: this.count += this.pagination_count
            })
                .then(r => {
                    this.orders = r.data.order;
                    this.count = r.data.count
                    this.max_pagination_count = r.data.max_pagination_count
                    this.max_pages = r.data.max_pages;
                });
        },

        setCurrentDate() {
            axios.post('/api/v1/insert-order/order/setCurrentDate').then((r) => {
                this.search_date_from = r.data.start_of_day;
                this.search_date_to = r.data.end_of_day;
            });
        }
    },

    created() {
        this.interval = setInterval(this.search, 5000)
    },

    beforeDestroy() {
        clearInterval(this.interval)
    },
}
</script>

<style lang="scss" scoped>
.export_dropdown {
    background-color: #fff;
    padding: 15px 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
}

.overlay {
    position: fixed;


    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;
    z-index: 999;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.5);

    .countdown {
        background-color: #ffffff;
        width: 300px;
        z-index: 1001;
        border-radius: 5px;

        display: block;
        padding: 15px;

        .form-group {
            text-align: center;
        }

        .content__header-btn {
            margin: 0 auto;
        }
    }
}

.status_nieuw {
    background-color: rgba(#46caf1, 0.2);
}

.status_geleverd {
    background-color: rgba(#77bc00, 0.2);
}

.status_aangesteld {
    background-color: rgba(#d95c33, 0.2);
}

.status_namdeorde {
    background-color: rgba(#ffa705, 0.2);
}

.poppins-bold {
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}
</style>
