/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// Front modules
require('./parts/menu');


window.Vue = require('vue');

var Toasted = require('vue-toasted').default;
var vueAwesomeCountdown = require('vue-awesome-countdown').default;
import excel from 'vue-excel-export'

Vue.use(Toasted);
Vue.use(vueAwesomeCountdown);
Vue.use(excel)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('establishment-create', require('./components/establishment/Create.vue').default);
Vue.component('establishment-index', require('./components/establishment/Index.vue').default);

//order
Vue.component('order-create', require('./components/order/Create.vue').default);
Vue.component('order-index', require('./components/order/Index.vue').default);

//report
Vue.component('report-index', require('./components/report/Index.vue').default);

//order map
Vue.component('order-map-map', require('./components/order/Map/Map2.vue').default);


//courier map
Vue.component('courier-map', require('./components/courier-map/CourierMap').default);

Vue.component('user-create', require('./components/user/Create.vue').default);
Vue.component('user-index', require('./components/user/Index.vue').default);

Vue.component('location-create', require('./components/location/Create.vue').default);
Vue.component('location-index', require('./components/location/Index.vue').default);

Vue.component('dashboard-index', require('./components/dashboard/Index.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
