<template>
    <div class="container-fluid">
        <div class="row d-flex justify-content-center">


            <div class=" col-lg-3 col-12 col-md-6 col-sm-12 content__form">
                <div class="row">
                    <div class="col-12 col-lg-6 col-md-12 col-sm-12">
                        <div class="form-group form-group--datepicker">
                            <!--<input type="date" id="datum-vanaf" class="form-input form-input&#45;&#45;datepicker" />-->
                            <label class="form-label" for="datum-vanaf">Datum vanaf</label>
                            <datetime placeholder="Datum" value-zone="Europe/Amsterdam" zone="Europe/Amsterdam" input-id="datum-vanaf" input-class="form-input" auto v-model="search_date_from">

                            </datetime>
                            <!--<span class="error-label">Already required</span>-->
                        </div>
                    </div>
                    <div class=" col-lg-6 col-md-12 col-sm-12 col-12">
                        <div class="form-group form-group--datepicker">
                            <!--<input type="date" id="datum-tot" class="form-input form-input&#45;&#45;datepicker" />-->
                            <label class="form-label" for="datum-tot">Datum tot</label>
                            <datetime placeholder="Datum" value-zone="Europe/Amsterdam" zone="Europe/Amsterdam" input-id="datum-tot" input-class="form-input" auto v-model="search_date_to"></datetime>
                            <!--<span class="error-label">Already required</span>-->
                        </div>
                    </div>

                    <div class="col-lg-12 col-12 col-sm-12 col-md-12">
                        <div class="form-group form-group--select">
                            <select v-model="selected_establishment"  id="search_establishment" class="form-select">
                                <option value="">-- Kiezen --</option>
                                <option v-for="(e, i) of establishments"
                                        :key="i"
                                        :value="e.id">
                                    {{ e.name }}
                                </option>
                            </select>
                            <label for="search_establishment" class="form-label">Filteren op restaurant</label>
                            <!--<span class="error-label">Already required</span>-->
                        </div>
                    </div>

                    <div class="col-lg-12 col-12 col-sm-12 col-md-12">
                        <button @click="filter" class="content__header-btn" >
                            Doorgaan
                        </button>
                    </div>


                </div>
            </div>



        </div>

        <div class="row bestellingen-table__wrapper">
            <table class="establishment-table">
                <thead>
                <tr>
                    <th class="centered">Periode</th>
                    <th>Restaurantnaam</th>
                    <th>Total</th>
                    <th>Bestand pdf</th>
                    <th>Factuur datum</th>
                </tr>
                </thead>
                <tbody>

                <!--orders-->
                <tr v-for="(report, index) of reports" :key="index">

                    <td class="centered poppins-bold">
                        {{report.period}}
                    </td>
                    <td>
                        {{ report.establishment_name }}
                    </td>

                    <td>
                        &euro;{{ report.total }}
                    </td>

                    <td>
                        <a :href="report.pdf" download>Download PDF</a>
                    </td>
                    <td>
                        {{ report.created_at }}
                    </td>

                </tr>
                <!--orders-->

                </tbody>
            </table>
        </div>


<!--        <div style="cursor: pointer" class="d-flex justify-content-center mt-5">-->


<!--            <button :disabled="count >= max_pagination_count" @click="showMore" >-->
<!--                Laat meer zien-->
<!--            </button>-->
<!--        </div>-->

<!--        <div class="row footer-text">-->
<!--            Pagina 1 van 0, 50 rijen zichtbaar van 0 totaal-->
<!--        </div>-->



    </div>
</template>

<script>
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';


export default {

    props: {
        establishments: false,
        user_establishment: Object,
        user_role: Number,
        date_from: false,
        date_to: false,
    },


    data() {
        return {

            selected_establishment: '',

            search_date_from: this.date_from,
            search_date_to: this.date_to,

            reports: [],


            // pagination_count: Number,
            // count: Number,
            // max_pagination_count: Number,

        }
    },



    mounted() {
        this.get_reports();
    },

    methods: {
        get_reports(filter=null) {
            axios.post('/api/v1/insert-order/report/scan', filter)
                .then(r => {
                    this.reports = r.data.data;
                    // this.pagination_count = this.count = r.data.count;
                    // this.max_pagination_count = r.data.max_pagination_count;
                });
        },

        filter() {
            this.get_reports({
                establishment: this.selected_establishment,
                date_from: this.search_date_from,
                date_to: this.search_date_to,
            })
        },

        // showMore(){
        //
        //     this.get_reports({
        //         count: this.count+=this.pagination_count,
        //         establishment: this.selected_establishment,
        //         date_from: this.search_date_from,
        //         date_to: this.search_date_to,
        //     });
        //
        // }
    },

}
</script>
