<template>
<div class="row content__map">
    <div class="col-11">
        <div class="content__map-left">
            <div v-if="selected_courier" class="left-block">
                <div v-if="courier" class="left-block-wrapper">
                    <div class="left-block__header">
                        <div class="left-block__header-avatar">
                            <img :src="courier.photo" alt="">
                        </div>
                        <div class="left-block__header-about">
                            <div class="left-block__header-about-name">
                                {{ courier.user_info.name }}
                            </div>
                            <div class="left-block__header-about-phone">
<!--                                <a href="#">54757</a>-->
                            </div>
                            <div class="left-block__header-about-status" :class="courier.free ? 'left-block__header-about-status--free' : 'left-block__header-about-status--busy'">
                                {{ courier.free ? 'free' : 'busy'}}
                                <svg x="0px" y="0px" viewBox="0 0 17.01 14.52">
                                    <g>
                                        <g>
                                            <path d="M10.38,2.97c0.69,0,1.25-0.56,1.25-1.26c0-0.69-0.56-1.26-1.25-1.26c-0.69,0-1.25,0.56-1.25,1.26
                                                            C9.13,2.41,9.69,2.97,10.38,2.97z M13.4,7.26c-1.99,0-3.6,1.63-3.6,3.63c0,2.01,1.61,3.63,3.6,3.63c1.99,0,3.6-1.63,3.6-3.63
                                                            C17.01,8.88,15.39,7.26,13.4,7.26z M3.6,7.26C1.61,7.26,0,8.88,0,10.89c0,2.01,1.61,3.63,3.6,3.63c1.99,0,3.6-1.63,3.6-3.63
                                                            C7.2,8.88,5.59,7.26,3.6,7.26z M10.09,5.86h0.48c0.12,0,0.24,0,0.36,0h1.45c0.33,0,0.6-0.27,0.6-0.61c0-0.33-0.27-0.61-0.6-0.61
                                                            h-2.1c-0.03-0.03-0.05-0.06-0.08-0.11C9.89,3.98,9.58,3.4,9.2,2.88C8.6,2.07,7.94,1.97,7.28,2.56C6.47,3.31,4.74,5.12,4.66,5.25
                                                            C4.41,5.68,4.42,6.12,4.7,6.51c0.16,0.23,0.4,0.42,0.64,0.56c0.74,0.42,1.5,0.81,2.26,1.19c0.17,0.09,0.24,0.18,0.25,0.36
                                                            c0,0.03-0.01,0.07-0.01,0.1v1.1c0,0.11,0,0.22,0,0.33v1.49c0,0.41,0.34,0.75,0.75,0.75c0.41,0,0.75-0.34,0.75-0.75v-0.44
                                                            c0-0.08,0-0.17,0-0.25c0-0.13,0-0.27,0-0.4V8.73c0-0.04,0-0.09-0.01-0.13c0-0.26,0-0.52,0-0.78c0-0.34-0.09-0.5-0.4-0.73
                                                            C8.72,6.93,7.48,6.24,7.15,6.05c0.49-0.53,0.96-1.06,1.47-1.61C8.88,4.85,9.1,5.2,9.34,5.53c0.1,0.14,0.27,0.3,0.42,0.31
                                                            c0.07,0,0.15,0.01,0.22,0.01C10.02,5.86,10.05,5.86,10.09,5.86z M3.43,4.84c0.25,0.26,0.67,0.26,0.92,0l2.43-2.45
                                                            c0.26-0.26,0.26-0.67,0-0.93L5.53,0.19c-0.25-0.26-0.67-0.26-0.92,0L2.18,2.65c-0.25,0.26-0.25,0.67,0,0.93L3.43,4.84z"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="left-block__body">
<!--                        <div class="left-block__body-info">-->
<!--                            <div class="left-block__body-info-item">-->
<!--                                <div class="left-block__body-info-item&#45;&#45;left">-->
<!--                                    Checked in:-->
<!--                                </div>-->
<!--                                <div class="left-block__body-info-item&#45;&#45;right">-->
<!--                                    09:00-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="left-block__body-info-item">-->
<!--                                <div class="left-block__body-info-item&#45;&#45;left">-->
<!--                                    Planned out:-->
<!--                                </div>-->
<!--                                <div class="left-block__body-info-item&#45;&#45;right">-->
<!--                                    20:00-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="left-block__body-info-item">-->
<!--                                <div class="left-block__body-info-item&#45;&#45;left">-->
<!--                                    Time to Destination:-->
<!--                                </div>-->
<!--                                <div class="left-block__body-info-item&#45;&#45;right">-->
<!--                                    15 <span>min</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="left-block__body-total">
                            <button type="button" class="btn-toggle__info" id="btn-toggle-info">

                            </button>
                            <div class="left-block__body-info-item">
                                <div class="left-block__body-info-item&#45;&#45;left">
                                    Today total:
                                </div>
                                <div class="left-block__body-info-item&#45;&#45;right">
                                    345 <span>km</span>
                                </div>
                            </div>
                        </div>-->
                        <div class="left-block__body-timeline">
                            <ul class="timeline">
                                <li v-for="(point,index) of courier.points" :key="index"
                                    class="timeline-item"
                                    :class="{'timeline-item--rest':point.icon_id == 1, 'timeline-item--client' : point.icon_id == 2, 'timeline-item--not-visited': point.status_id == 1}"
                                >
                                    <span class="timeline-item__circle"></span>
                                    <div class="timeline-inner">
                                        <div class="timeline-item__icon">
                                            <svg v-if="point.icon_id == 1" x="0px" y="0px" viewBox="0 0 6.6 11.09" >
                                                <g>
                                                    <g>
                                                        <path d="M2.88,0c-0.1,0-0.19,0.08-0.2,0.18l-0.34,2.7c-0.01,0.1-0.1,0.18-0.2,0.18H2.12c-0.11,0-0.2-0.08-0.2-0.19L1.75,0.19
                                                                        C1.74,0.08,1.65,0,1.54,0h0c-0.11,0-0.2,0.08-0.2,0.19L1.17,2.87c-0.01,0.11-0.1,0.19-0.2,0.19H0.95c-0.1,0-0.19-0.08-0.2-0.18
                                                                        l-0.34-2.7C0.39,0.08,0.31,0,0.2,0C0.09,0,0,0.09,0,0.2v2.85c0,0.63,0.39,1.2,0.98,1.42c0.08,0.03,0.13,0.11,0.13,0.2l-0.3,5.64
                                                                        c-0.03,0.4,0.27,0.75,0.68,0.78s0.75-0.27,0.78-0.67c0-0.04,0-0.07,0-0.11l-0.3-5.64c0-0.09,0.05-0.17,0.13-0.2
                                                                        c0.59-0.23,0.98-0.79,0.98-1.42V0.2C3.08,0.09,2.99,0,2.88,0z M6.28,0.12C6.28,0.05,6.22,0,6.16,0c-0.11,0-0.57,0.17-1.2,1.63
                                                                        C4.32,3.09,4.2,4.45,4.36,5c0.15,0.5,0.59,0.46,1.1,0.47c0.08,0,0.15,0.07,0.14,0.16l-0.46,4.68c-0.03,0.4,0.27,0.75,0.68,0.78
                                                                        c0.4,0.03,0.75-0.27,0.78-0.67c0-0.04,0-0.07,0-0.11L6.28,0.12z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                            <svg v-else x="0px" y="0px" viewBox="0 0 9.01 8.7">
                                                <path d="M8.86,3.86L4.82,0.12c-0.18-0.16-0.44-0.16-0.62,0L0.15,3.86C0.01,3.99-0.04,4.2,0.03,4.38c0.07,0.18,0.24,0.3,0.43,0.3
                                                            h0.65v3.75c0,0.15,0.12,0.27,0.26,0.27h2.22c0.15,0,0.26-0.12,0.26-0.27V6.15h1.3v2.28c0,0.15,0.12,0.27,0.26,0.27h2.22
                                                            c0.15,0,0.26-0.12,0.26-0.27V4.68h0.65c0.19,0,0.36-0.12,0.43-0.3C9.05,4.2,9,3.99,8.86,3.86z"/>
                                            </svg>
                                        </div>
                                        <div class="timeline-item__value">
                                            <div class="row">
                                                <div class="col-9">
                                                    <div class="timeline-item__address">
                                                        {{ point.address  }}<span>{{ point.name }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="timeline-item__time">eta:<span>09:20 pm</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                            </ul>


                            <div class="timeline-controls">
<!--                                <div class="timeline-controls__item draggable">
                                    <div class="timeline-controls__item-icon">
                                        <svg x="0px" y="0px" viewBox="0 0 6.6 11.09" >
                                            <g>
                                                <g>
                                                    <path d="M2.88,0c-0.1,0-0.19,0.08-0.2,0.18l-0.34,2.7c-0.01,0.1-0.1,0.18-0.2,0.18H2.12c-0.11,0-0.2-0.08-0.2-0.19L1.75,0.19
                                                                    C1.74,0.08,1.65,0,1.54,0h0c-0.11,0-0.2,0.08-0.2,0.19L1.17,2.87c-0.01,0.11-0.1,0.19-0.2,0.19H0.95c-0.1,0-0.19-0.08-0.2-0.18
                                                                    l-0.34-2.7C0.39,0.08,0.31,0,0.2,0C0.09,0,0,0.09,0,0.2v2.85c0,0.63,0.39,1.2,0.98,1.42c0.08,0.03,0.13,0.11,0.13,0.2l-0.3,5.64
                                                                    c-0.03,0.4,0.27,0.75,0.68,0.78s0.75-0.27,0.78-0.67c0-0.04,0-0.07,0-0.11l-0.3-5.64c0-0.09,0.05-0.17,0.13-0.2
                                                                    c0.59-0.23,0.98-0.79,0.98-1.42V0.2C3.08,0.09,2.99,0,2.88,0z M6.28,0.12C6.28,0.05,6.22,0,6.16,0c-0.11,0-0.57,0.17-1.2,1.63
                                                                    C4.32,3.09,4.2,4.45,4.36,5c0.15,0.5,0.59,0.46,1.1,0.47c0.08,0,0.15,0.07,0.14,0.16l-0.46,4.68c-0.03,0.4,0.27,0.75,0.68,0.78
                                                                    c0.4,0.03,0.75-0.27,0.78-0.67c0-0.04,0-0.07,0-0.11L6.28,0.12z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="timeline-controls__item-value">
                                        <div class="timeline-controls__item-value-address">
                                            <div class="timeline-item__address">
                                                Saturnstraat 11, 1824AC<span>Restaurant #1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
<!--                                <div v-for="(c,index) of courier.points" :key="index" class="timeline-controls__item draggable">-->
                                <div v-for="(c,index) of orderCoordinates" :key="index" class="timeline-controls__item draggable">
                                    <div class="timeline-controls__item-icon">
                                        <svg v-if="c.icon_id == 1" x="0px" y="0px" viewBox="0 0 6.6 11.09" >
                                            <g>
                                                <g>
                                                    <path d="M2.88,0c-0.1,0-0.19,0.08-0.2,0.18l-0.34,2.7c-0.01,0.1-0.1,0.18-0.2,0.18H2.12c-0.11,0-0.2-0.08-0.2-0.19L1.75,0.19
                                                                    C1.74,0.08,1.65,0,1.54,0h0c-0.11,0-0.2,0.08-0.2,0.19L1.17,2.87c-0.01,0.11-0.1,0.19-0.2,0.19H0.95c-0.1,0-0.19-0.08-0.2-0.18
                                                                    l-0.34-2.7C0.39,0.08,0.31,0,0.2,0C0.09,0,0,0.09,0,0.2v2.85c0,0.63,0.39,1.2,0.98,1.42c0.08,0.03,0.13,0.11,0.13,0.2l-0.3,5.64
                                                                    c-0.03,0.4,0.27,0.75,0.68,0.78s0.75-0.27,0.78-0.67c0-0.04,0-0.07,0-0.11l-0.3-5.64c0-0.09,0.05-0.17,0.13-0.2
                                                                    c0.59-0.23,0.98-0.79,0.98-1.42V0.2C3.08,0.09,2.99,0,2.88,0z M6.28,0.12C6.28,0.05,6.22,0,6.16,0c-0.11,0-0.57,0.17-1.2,1.63
                                                                    C4.32,3.09,4.2,4.45,4.36,5c0.15,0.5,0.59,0.46,1.1,0.47c0.08,0,0.15,0.07,0.14,0.16l-0.46,4.68c-0.03,0.4,0.27,0.75,0.68,0.78
                                                                    c0.4,0.03,0.75-0.27,0.78-0.67c0-0.04,0-0.07,0-0.11L6.28,0.12z"/>
                                                </g>
                                            </g>
                                        </svg>



                                        <svg v-else-if="c.icon_id == 2" x="0px" y="0px" viewBox="0 0 9.01 8.7">
                                            <path d="M8.86,3.86L4.82,0.12c-0.18-0.16-0.44-0.16-0.62,0L0.15,3.86C0.01,3.99-0.04,4.2,0.03,4.38c0.07,0.18,0.24,0.3,0.43,0.3
                                                        h0.65v3.75c0,0.15,0.12,0.27,0.26,0.27h2.22c0.15,0,0.26-0.12,0.26-0.27V6.15h1.3v2.28c0,0.15,0.12,0.27,0.26,0.27h2.22
                                                        c0.15,0,0.26-0.12,0.26-0.27V4.68h0.65c0.19,0,0.36-0.12,0.43-0.3C9.05,4.2,9,3.99,8.86,3.86z"/>
                                        </svg>
                                    </div>
                                    <div class="timeline-controls__item-value">
                                        <div class="timeline-controls__item-value-address">
                                            <div class="timeline-item__address">
                                                {{  c.address }}<span>{{ c.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
<!--                                <div class="timeline-controls__item draggable">-->
<!--                                    <div class="timeline-controls__item-icon">-->
<!--                                        <svg x="0px" y="0px" viewBox="0 0 9.01 8.7">-->
<!--                                            <path d="M8.86,3.86L4.82,0.12c-0.18-0.16-0.44-0.16-0.62,0L0.15,3.86C0.01,3.99-0.04,4.2,0.03,4.38c0.07,0.18,0.24,0.3,0.43,0.3-->
<!--                                                        h0.65v3.75c0,0.15,0.12,0.27,0.26,0.27h2.22c0.15,0,0.26-0.12,0.26-0.27V6.15h1.3v2.28c0,0.15,0.12,0.27,0.26,0.27h2.22-->
<!--                                                        c0.15,0,0.26-0.12,0.26-0.27V4.68h0.65c0.19,0,0.36-0.12,0.43-0.3C9.05,4.2,9,3.99,8.86,3.86z"/>-->
<!--                                        </svg>-->
<!--                                    </div>-->
<!--                                    <div class="timeline-controls__item-value">-->
<!--                                        <div class="timeline-controls__item-value-address">-->
<!--                                            <div class="timeline-item__address">-->
<!--                                                Saturnstraat 33, 1824AC<span>Restaurant #3</span>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                    <div v-if="order.status_id == 1 && !isDone" class="left-block__footer">
                        <button v-if="status == 1" @click="assignPoint" class="content__header-btn">Assign</button>
                        <button v-else-if="status == 2" @click="sendPoint" class="content__header-btn">Ok</button>
                    </div>
                </div>
                <div v-else>Loading....</div>


            </div>

            <div class="google-map">
               <div id="map"></div>
            </div>
        </div>
    </div>
    <div class="col-1">
        <div class="content__map-right">
            <button type="button" class="map-control map-control--zoom-in">
                <span></span>
                <span></span>
            </button>
            <button type="button" class="map-control map-control--zoom-out">
                <span></span>
            </button>
        </div>
    </div>

</div>
</template>

<script>

import mapStyle from './map_style.json';

export default {
    name: "Map",

    props: {
        order: Object
    },

    data() {
        return {
            map: false,
            info_window: false,
            bounds: false,

            orderCoordinates: [],
            status: 0,

            locations: [],

            cluster_options: {
                url: 'images/google-map/cluster.svg',
                height: 50,
                width: 50,
                textColor: '#000000',
                textSize: 16
            },


            selected_courier: false,
            courier: false,


            isDone: false,
        }
    },

    mounted() {
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: {lat:52.00354561177717, lng: 4.360779096937575},
            zoom: 13,
            styles: mapStyle
        })


        this.getCouriers();
    },

    watch: {
      locations() {
          this.setMap()
      },

      selected_courier() {
          this.orderCoordinates = [];
          this.status = 1;
          if(this.selected_courier != false) {
              this.loadCourierInfo();
          }
      },

      status(val) {
          console.log(val)
      }
    },

    methods: {
        setMap() {

            this.bounds = new google.maps.LatLngBounds();
            this.info_window = new google.maps.InfoWindow({content: ''});


            let markers = this.locations.map((location) => {
                    let m = new google.maps.Marker({
                        position: location.coordinates,
                        marker: location,
                        map: this.map,
                        icon: {
                            url: 'images/google-map/' + location.type + '.svg',
                            anchor: new google.maps.Point(20, 47),
                            scaledSize: new google.maps.Size(40, 47)
                        },
                        draggable: false
                    })

                    m.note = '<div class="marker">'+location.info.name+'</div>';

                    m.addListener('click', () => {
                        if(location.courier) {
                            this.selected_courier = location.info.id;
                        }
                        this.info_window.setContent(m.note);
                        this.map.setCenter(location.coordinates);
                        this.info_window.open(this.map, m);
                    });


                this.bounds.extend(m.position);

                    return m;
            });

            new MarkerClusterer(this.map, markers, {
                styles: [this.cluster_options]
            });


            google.maps.event.addListener(this.info_window, 'closeclick', ()=> {
                this.selected_courier = false;
            });

            this.map.fitBounds(this.bounds);
            this.map.panToBounds(this.bounds);
        },

        getCouriers() {
            axios.post('/api/v1/insert-order/courier/list', {
                order: this.order.id
            }).then((r)=>{
                this.locations = r.data;
            });
        },

        loadCourierInfo() {
            axios.post('/api/v1/insert-order/courier/info', {
                id: this.selected_courier
            }).then((r)=>{
                this.courier = r.data;
            });
        },

        assignPoint() {
            this.orderCoordinates = [
                {
                    //TODO - NEW FIELD ADDED, NEED TO CHECK IT
                    reference: this.order.reference,
                    description: this.order.client_description,

                    address: this.order.restaurant_address,
                    name: this.order.establishment.name,
                    icon_id: 1,
                    status_id: 1,
                    order_id: this.order.id,
                    user_id: this.courier.id,
                    latitude: this.order.establishment.lat,
                    longitude: this.order.establishment.lng,
                    price: this.order.price,
                    paid: this.order.paid,
                    time: this.order.time
                },
                {
                    //TODO - NEW FIELD ADDED, NEED TO CHECK IT
                    reference: this.order.reference,
                    description: this.order.client_description,

                    address: this.order.client_address,
                    name: this.order.client_name,
                    icon_id: 2,
                    status_id: 1,
                    order_id: this.order.id,
                    user_id: this.courier.id,
                    latitude: this.order.client_lat,
                    longitude: this.order.client_lng,
                    price: this.order.price,
                    paid: this.order.paid,
                    time: this.order.time,
                },
            ];
            this.status = 2;

            console.log(this.orderCoordinates);
        },
        sendPoint() {
            axios.post('/api/v1/insert-order/courier/assignCourier', {
                order: this.order.id,
                courier: this.selected_courier,
                points: this.orderCoordinates
            }).then((r)=>{
                this.loadCourierInfo();
                this.orderCoordinates = [];
                this.status = 3;
                this.selected_courier = false;
                this.isDone = true;
                this.info_window.close();
            });
        }
    }
}
</script>

<style scoped>
    #map {
        width: 100%;
        height: 100%;
    }
</style>
