<template>
    <div class="container-fluid">
        <div class="form-border">
            <div class="col-12 form-wrapper col-md-12 col-lg-8 col-sm-12">
                <div class="row ">


                    <!--input-->
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input id="login" v-model="name" class="form-input" placeholder=" ">
                            <label for="login" class="form-label">Locatie naam <span class="required">*</span></label>
                            <span v-if="error_list.name" class="error-label">{{ error_list.name[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="longitude" v-model="longitude" class="form-input" placeholder=" ">
                            <label for="login" class="form-label">Longitude <span class="required">*</span></label>
                            <span v-if="error_list.longitude" class="error-label">{{ error_list.longitude[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="latitude" v-model="latitude" class="form-input" placeholder=" ">
                            <label for="login" class="form-label">Latitude <span class="required">*</span></label>
                            <span v-if="error_list.latitude" class="error-label">{{ error_list.latitude[0] }}</span>
                        </div>
                    </div>
                    <!--input-->


                </div>
            </div>
        </div>

        <div class="">
            <div class="form-buttons">
                <button @click="createLocation" class="btn-form-submit btn-form-submit--blue">Save</button>
            </div>
        </div>
    </div>

</template>

<script>
import Multiselect from 'vue-multiselect'
import Switches from 'vue-switches';

export default {
    name: "Create",

    props: ['location'],

    data() {
        return {

            name: this.location ? this.location.name : '',
            longitude: this.location ? this.location.longitude : '',
            latitude: this.location ? this.location.latitude : '',

            error_list: {},

        }
    },

    methods: {
        createLocation() {
            axios.post('/api/v1/insert-order/location/save', {
                actionType: this.location ? 'update' : 'save',
                locationId: this.location ? this.location.id : false,
                name: this.name,
                longitude: this.longitude,
                latitude: this.latitude,
            })
                .then(r => {
                    this.error_list = false;

                    if(this.location) {
                        this.$toasted.show('Location has been changed!', {
                            theme: "bubble",
                            position: "bottom-center",
                            duration: 3000
                        })
                    } else {
                        setTimeout(function () {
                            window.location.href = '/insert-order/location'
                        }, 300);
                    }

                })
                .catch(e => {
                    this.error_list = e.response.data.errors
                });
        }
    }
}
</script>
