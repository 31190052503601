<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-12 content__header col-md-6 col-sm-12">
                <div v-if="role === 2 && !is_deputy" class="form-group form-group--select">
                    <select v-model="filter.location" id="search_establishment" class="form-select">
                        <option value="">-- All --</option>
                        <option v-for="(location, index) of locations" :key="index" :value="location.id">
                            {{ location.name }}
                        </option>
                    </select>
                    <label for="search_establishment" class="form-label">Location</label>
                    <!--<span class="error-label">Already required</span>-->
                </div>

                <div v-if="role === 2 && detail" class="form-group form-group--select">
                    <select v-model="filter.restaurant" id="search_restaurant" class="form-select">
                        <option value="">-- All --</option>
                        <option v-for="(restaurant, index) of restaurants_data" :key="index" :value="restaurant.id">
                            {{ restaurant.name }}
                        </option>
                    </select>
                    <label for="search_restaurant" class="form-label">Restaurant</label>
                    <!--<span class="error-label">Already required</span>-->
                </div>

            </div>
            <div class="col-lg-6 col-12 col-md-6 col-sm-12 ">
                <div class="row">
                    <div class="col-12 col-lg-4 col-md-12 col-sm-12">
                        <div class="form-group form-group--datepicker">
                            <!--<input type="date" id="datum-vanaf" class="form-input form-input&#45;&#45;datepicker" />-->
                            <label class="form-label">Datum vanaf</label>
                            <datetime placeholder="Datum" value-zone="Europe/Amsterdam" zone="Europe/Amsterdam"
                                      input-id="datum-vanaf" input-class="form-input" auto type="datetime"
                                      v-model="filter.dateFrom">

                            </datetime>
                            <!--<span class="error-label">Already required</span>-->
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="form-group form-group--datepicker">
                            <!--<input type="date" id="datum-tot" class="form-input form-input&#45;&#45;datepicker" />-->
                            <label class="form-label">Datum tot</label>
                            <datetime placeholder="Datum" value-zone="Europe/Amsterdam" zone="Europe/Amsterdam"
                                      input-id="datum-tot" input-class="form-input" auto type="datetime"
                                      v-model="filter.dateTo"></datetime>
                            <!--<span class="error-label">Already required</span>-->
                        </div>
                    </div>
                    <div
                        class="col-lg-4 col-12 col-md-12 content__controls-wrapper content__controls-wrapper--dashboard">

                        <button v-if="!detail" @click="applyFilter"
                                class="content__header-btn">
                            Zoek
                        </button>
                        <button  v-else-if="detail" @click="applyDetailFilter"
                                class="content__header-btn">
                            Zoek
                        </button>
                    </div>
                    <div class="col-lg-8 col-md-12 col-sm-12 col-12 dashboard__controls">
                        <button @click="setFilterTime('now')" class="dashboard__btn">Today</button>
                        <button @click="setFilterTime('month')" class="dashboard__btn">This month</button>
                        <button @click="setFilterTime('prev-month')" class="dashboard__btn">Prev. month</button>
                        <button @click="setFilterTime('year')" class="dashboard__btn">This year</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 offset-lg-1 col-12 col-sm-12 col-md-6 dashboard__export">
                <div class="content__logo">
                    <img src="/images/logo.svg" alt="FoodLyft logo">
                </div>
                <div class="content__export">
                    <button class="btn-export--excel">
                        <img src="/images/excel.svg"/>
                    </button>
                </div>
            </div>
        </div>
<!--        {{ detail_data }}-->
        <div v-if="!detail">
            <div style="display: flex;justify-content: center" v-if="detailLoading"><img src="/images/loader.svg" alt="Loading..."></div>
            <div v-else class="row">
                <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="dashboard__table">
                        <div class="dashboard__table-header dashboard__table-header--green">
                            Orders
                        </div>
                        <div style="display: flex;justify-content: center" v-if="loading"><img width="120"
                                                                                               src="/images/loader.svg"
                                                                                               alt="loading..."></div>
                        <div v-else class="dashboard__table-body">
                            <div v-if="result && result.orders" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Total orders:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.orders.total_orders
                                    }}
                                </div>
                            </div>
                            <div v-if="result && result.orders" class="row step">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Total orders value:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">&euro;
                                    {{ result.orders.total_orders_value }}
                                </div>
                            </div>
                            <div v-if="result && result.orders" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Orders < 40 Euro:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.orders.orders_to_40
                                    }}
                                </div>
                            </div>
                            <div v-if="result && result.orders" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Orders 40-80 Euro:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">
                                    {{ result.orders.orders_from_40_to_80 }}
                                </div>
                            </div>
                            <div v-if="result && result.orders" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Orders > 80 Euro:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.orders.orders_from_80
                                    }}
                                </div>
                            </div>

                            <div v-if="result && result.orders" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Deleted orders:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.orders.deleted_orders
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__table-more">
                        <a @click.prevent="ordersDetail('orders')" href="#">See details ></a>
                    </div>
                </div>

                <div v-if="role == 2" class="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="dashboard__table dashboard__table">
                        <div class="dashboard__table-header dashboard__table-header--red">
                            Invoices
                        </div>
                        <div style="display: flex;justify-content: center" v-if="loading"><img width="120"
                                                                                               src="/images/loader.svg"
                                                                                               alt="loading..."></div>
                        <div v-else class="dashboard__table-body">
                            <div v-if="result && result.invoices" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Invoices sent:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">&euro; {{result.invoices.invoices_sent }}</div>
                            </div>
                            <div v-if="result && result.invoices" class="row step">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Paid:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{ result.invoices.paid }}</div>
                            </div>
                            <div v-if="result && result.invoices" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Due:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{ result.invoices.due }}</div>
                            </div>
                            <div v-if="result && result.invoices" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Overdue invoices:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.invoices.overdue_invoices
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="dashboard__table dashboard__table">
                        <div class="dashboard__table-header dashboard__table-header--yellow">
                            Delivery
                        </div>
                        <div style="display: flex;justify-content: center" v-if="loading"><img width="120"
                                                                                               src="/images/loader.svg"
                                                                                               alt="loading..."></div>
                        <div v-else class="dashboard__table-body">
                            <div v-if="result && result.delivery" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Total deliveries:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.delivery.total_del
                                    }}
                                </div>
                            </div>

                            <div v-if="result && result.delivery" class="row step">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Regular del.:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.delivery.regular_del
                                    }}
                                </div>
                            </div>
                            <div v-if="result && result.delivery" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">ASAP del.:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{ result.delivery.asap_del }}</div>
                            </div>
                            <div v-if="result && result.delivery" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Preset time del.:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.delivery.preset_time_del
                                    }}
                                </div>
                            </div>
                            <div v-if="result && result.delivery" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Average pickup wait:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.delivery.avg_pickup_wait
                                    }}
                                </div>
                            </div>
                            <div v-if="result && result.delivery" class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">Average delivery delay:</div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6 count">{{
                                        result.delivery.avg_del_delay
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard__table-more">
                        <a @click.prevent="ordersDetail('delivery')" href="#">See details ></a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="">
            <div style=" background-colorred;display: flex;justify-content: center" v-if="detailLoading"><img src="/images/loader.svg" alt="Loading..."></div>
            <div v-else>
                <div v-if="detail_data.type && detail_data.type === 'orders'">
                    <table class="bestellingen-table bestellingen-table--dashboard">
                        <thead>
                        <tr>
                            <th>Restaurant name</th>
                            <th>Location</th>
                            <th>< 40</th>
                            <th>40-80</th>
                            <th>>80</th>
                            <th>Cancelled</th>
                            <th>Total orders</th>
                            <th>Value</th>
                            <th>Waiting time</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(d, index) of detail_data.result" :key="index">
                            <td>{{ d.restaurant_name }}</td>
                            <td>{{ d.location_name }}</td>
                            <td>{{  d.to_40 }}</td>
                            <td>{{  d.from_40_to_80 }}</td>
                            <td>{{ d.from_80 }}</td>
                            <td>{{ d.is_deleted}}</td>
                            <td>{{ d.total_orders }}</td>
                            <td>&euro; {{ d.sum_price }}</td>
                            <td>Waiting time</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <table class="bestellingen-table bestellingen-table--dashboard">
                        <thead>
                        <tr>
                            <th>Restaurant name</th>
                            <th>Location</th>
                            <th>Total deliveries</th>
                            <th>Regular deliveries</th>
                            <th>ASAP deliveries</th>
                            <th>Preset time deliveries</th>
                            <th>Average pickup wait:</th>
                            <th>Average delivery delay</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(d, index) of detail_data.result" :key="index">
                            <td>{{ d.restaurant_name }}</td>
                            <td>{{ d.location_name }}</td>
                            <td>{{  d.total }}</td>
                            <td>{{  d.regular }}</td>
                            <td>{{ d.asap }}</td>
                            <td>{{ d.preset_time}}</td>
                            <td>{{ d.avg_pickup }}</td>
                            <td>{{ d.avg_delivery_delay }}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div class="dashboard__table-more">
                    <a @click.prevent="backToDashboard" href="#">< Back to dashboard</a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "Index",

    props: ['locations', 'role', 'is_deputy'],

    data() {
        return {
            filter: {
                dateFrom: null,
                dateTo: null,

                location: "",
                restaurant: "",
            },

            result: [],
            detail: false,
            detail_data: [],
            loading: true,
            detailLoading: false,
            restaurants_data: false,
        }
    },

    mounted() {
        this.setFilterTime();
        this.applyFilter();
    },

    watch: {
        detail(newVal) {
            if (newVal === true) {
                this.getRestaurantsData()
            } else {
                this.restaurants_data = false;
            }
        },

        'filter.location': function (newVal, oldVal) {
            this.restaurants_data = null;
            this.filter.restaurant = "";
            this.getRestaurantsData();
        },
    },

    methods: {
        setFilterTime(type = 'now') {
            axios.post('/api/v1/insert-order/dashboard/set-date', {
                type: type
            }).then(r => {
                this.filter.dateFrom = r.data.dateFrom;
                this.filter.dateTo = r.data.dateTo;
            });
        },

        ordersDetail(type) {
            this.detailLoading = true;
            axios.post('/api/v1/insert-order/dashboard/orders-detail', {
                filter: this.filter,
                type: type
            }).then(r => {
                this.detail = true;
                this.detail_data = r.data;
                this.detailLoading = false;
            });
        },

        backToDashboard() {
            this.detail = false;
            this.filter.restaurant = "";
            this.detail_data = [];
        },

        getRestaurantsData() {
            axios.post('/api/v1/insert-order/dashboard/get-restaurants', {
                location: this.filter.location
            }).then(r => {
                this.restaurants_data = r.data.data;
            });
        },

        applyFilter() {
            this.loading = true;
            axios.post('/api/v1/insert-order/dashboard/filter', {
                filter: this.filter
            }).then(r => {
                this.result = r.data.data;
                this.loading = false;
            });
        },

        applyDetailFilter() {
            this.ordersDetail(this.detail_data.type);
        }

    }
}
</script>

<style scoped>

</style>
