<template>
    <div class="container-fluid">
        <div class=" form-border">
            <div class="col-12 form-wrapper col-md-12 col-lg-8 col-sm-12">
                <div class="row ">

                    <!--select-->
                    <div class="col-12 col-lg-12 col-sm-12 col-12">
                        <div class="form-group form-group--select">
                            <select name="select" v-model="selected_establishment" id="establishment" class="form-select">
                                <option value="" disabled>-- Kiezen --</option>
                                <option v-for="(establishment, index) in establishments" :key="index" :value="establishment.id">
                                    {{ establishment.name }}
                                </option>
                            </select>
                            <label for="establishment" class="form-label">Vestiging</label>
                            <span v-if="error_list.establishment" class="error-label">{{  error_list.establishment[0] }}</span>
                        </div>
                    </div>
                    <!--select-->

                    <!--input-->
                    <div class="col-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <input id="order_reference" v-model="fields.reference" class="form-input" placeholder=" ">
                            <label for="order_reference" class="form-label">Bestelling referentie</label>
                            <span v-if="error_list['order.reference']" class="error-label">{{ error_list['order.reference'][0] }}</span>
                        </div>
                    </div>
                    <!--input-->



                    <!--col-->
                    <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group">
                            <input id="street" v-model="fields.street" class="form-input" placeholder=" ">
                            <label for="street" class="form-label">Ophaal straat <span class="required">*</span></label>
                            <span v-if="error_list['order.street']" class="error-label">{{  error_list['order.street'][0] }}</span>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3 col-md-3 col-sm-6">
                        <div class="form-group">
                            <input id="house_number" v-model="fields.house_number"  class="form-input" placeholder=" ">
                            <label for="house_number" class="form-label">Huisnummer <span class="required">*</span></label>
                            <span v-if="error_list['order.house_number']" class="error-label">{{  error_list['order.house_number'][0] }}</span>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3 col-md-3 col-sm-6">
                        <div class="form-group">
                            <input id="letter" v-model="fields.letter"  class="form-input" placeholder=" ">
                            <label for="letter" class="form-label">Letter</label>
                            <span v-if="error_list['order.letter']" class="error-label">{{  error_list['order.letter'][0] }}</span>
                        </div>
                    </div>
                    <!--col-->

                    <!--col-->
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="postal_code" v-model="fields.postal_code" class="form-input" placeholder=" ">
                            <label for="postal_code" class="form-label">Postcode <span class="required">*</span></label>
                            <span v-if="error_list['order.postal_code']" class="error-label">{{  error_list['order.postal_code'][0] }}</span>
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="city" v-model="fields.city"  class="form-input" placeholder=" ">
                            <label for="city" class="form-label">Ophaal stad <span class="required">*</span></label>
                            <span v-if="error_list['order.city']" class="error-label">{{  error_list['order.city'][0] }}</span>
                        </div>
                    </div>
                    <!--col-->
                    <!--input-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group form-group--datepicker">
                            <label for="order_time" class="form-label">Bezorg tijd</label>
                            <datetime :disabled="fields.delivery_time_asap == true" value-zone="Europe/Amsterdam" zone="Europe/Amsterdam" input-id="order_time" input-class="form-input" auto type="datetime" v-model="fields.time"></datetime>
                        </div>
                    </div>
                    <!--input-->
                    <!--checkbox-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group form-group--checkbox">
                            <label for="delivery_time_asap" class="checkbox-button">
                                <input onclick="return confirm('Bevestig de express levering?')" v-model="fields.delivery_time_asap" type="checkbox" class="checkbox-button__input" id="delivery_time_asap">
                                <span class="checkbox-button__control"></span>
                                <span class="checkbox-button__label">Zo snel mogelijk</span>
                                <div class="checkbox-button__icon">
                                    <img src="/images/bike.svg" alt="bike">
                                </div>
                            </label>
                        </div>
                    </div>
                    <!--checkbox-->

                    <!--col-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="client_name" v-model="client.name" class="form-input" placeholder=" ">
                            <label for="client_name" class="form-label">Klant naam</label>

                            <div class="d-flex justify-content-around">
                                <div v-for="(result, index) of find__client_name" :key="index">
                                    <small
                                        style="cursor: pointer;font-size: 12px;"
                                        @click="setClientName(result)">
                                        {{ result.client_name }}
                                    </small>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="client_phone" v-model="client.phone"  class="form-input" placeholder=" ">
                            <label for="client_phone" class="form-label">Klant telefoonnummer</label>
                            <span v-if="error_list['client.phone']" class="error-label">{{  error_list['client.phone'][0] }}</span>
                        </div>
                    </div>
                    <!--col-->

                    <!--col-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="client_street" v-model="client.street" class="form-input" placeholder=" ">
                            <label for="client_street" class="form-label">Aflever straat <span class="required">*</span></label>
                            <span v-if="error_list['client.street']" class="error-label">{{  error_list['client.street'][0] }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="client_house_number" v-model="client.house_number"  class="form-input" placeholder=" ">
                            <label for="client_house_number" class="form-label">Huisnummer en letter <span class="required">*</span></label>
                            <span v-if="error_list['client.house_number']" class="error-label">{{  error_list['client.house_number'][0] }}</span>
                        </div>
                    </div>
                    <!--<div class="col-3">
                        <div class="form-group">
                            <input id="client_letter" v-model="client.letter"  class="form-input" placeholder=" ">
                            <label for="client_letter" class="form-label">Letter</label>
                            <span v-if="error_list['client.letter']" class="error-label">{{  error_list['client.letter'][0] }}</span>
                        </div>
                    </div>-->
                    <!--col-->

                    <!--col-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="client_postal_code" v-model="client.postal_code" class="form-input" placeholder=" ">
                            <label for="client_postal_code" class="form-label">Postcode <span class="required">*</span></label>
                            <span v-if="error_list['client.postal_code']" class="error-label">{{  error_list['client.postal_code'][0] }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="client_city" v-model="client.city"  class="form-input" placeholder=" ">
                            <label for="client_city" class="form-label">Aflever stad <span class="required">*</span></label>
                            <span v-if="error_list['client.city']" class="error-label">{{  error_list['client.city'][0] }}</span>
                        </div>
                    </div>
                    <!--col-->

                    <!--textarea-->
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="form-group">
                            <textarea id="description" v-model="client.description" class="form-textarea" placeholder=" "></textarea>
                            <label for="description" class="form-label" placeholder=" ">Afleverinstructies </label>
                        </div>
                    </div>
                    <!--textarea-->

                   <!--input and checkbox-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input v-model="fields.price" id="price" class="form-input" type="number" placeholder=" ">
                            <label for="price" class="form-label">Prijs</label>
                            <span v-if="error_list['order.price']" class="error-label">{{  error_list['order.price'][0] }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-sm-6 d-flex ">
                        <div class="form-group form-group--checkbox">
                            <label for="paid" class="checkbox-button">
                                <input v-model="fields.paid" type="checkbox" class="checkbox-button__input" id="paid">
                                <span class="checkbox-button__control"></span>
                                <span class="checkbox-button__label">Betaald</span>
                            </label>
                        </div>
                    </div>
                   <!--input and checkbox-->

                   <!-- link-->
                    <div v-if="order" class="col-12 col-lg-12 col-sm-12">
                        <div class="form-group form-group--link">
                            <a :href="`/order_` + order.link">{{ copy }}</a>
                            <div @click="copyLink" class="copy-link-icon">
                                <img src="/images/copy.svg" alt="copy link">
                                <span v-if="copied" class="copied-link">Copied</span>
                            </div>
                        </div>
                    </div>
         <!--           <div v-if="order" class="form-group">
                        <a :href="`/order_` + order.link"><i class="fa fa-clone" aria-hidden="true"></i> Bekijk de bestelstatus</a>
                    </div>-->
                   <!-- link-->

                </div>
            </div>
        </div>


        <div class="">
            <div class="form-buttons">
                <button v-if="!saving" @click="saveOrder"  class="btn-form-submit btn-form-submit--blue">Verzenden</button>
<!--                <button class="btn-form-submit btn-form-submit&#45;&#45;green">Kies een koerier</button>-->
            </div>
        </div>
    </div>
</template>

<script>
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

var d1 = new Date (),
    d2 = new Date ( d1 );

d2.setMinutes(d1.getMinutes() + 30 );

export default {
    name: "Create",

    components: {
        datetime: Datetime
    },

    props: {
      establishments: false,
      link: false,
      order: false
    },

    data() {
        return {

            //selected_establishment: this.order.establishment_id ?? (this.establishments ? this.establishments[0].id : ''),
            selected_establishment: this.order.establishment_id ?? (this.establishments ? this.establishments[0].id : ''),

            establishment_info: this.order ? true : false,

            find__client_name: false,


            fields: {
              reference: this.order.reference ?? '',
              time: this.order.time ?? d2.toISOString(),
              street: this.order.street ?? '',
              letter: this.order.letter ?? '',
              house_number: this.order.house_number ?? '',
              postal_code: this.order.postal_code ?? '',
              city: this.order.city ?? '',
              delivery_time_asap: this.order.delivery_time_asap ?? true,
              paid: this.order.paid ?? false,
              price: this.order.price ?? '',
            },

            client: {
              name: this.order.client_name ?? '',
              phone: this.order.client_phone ?? '',
              street: this.order.client_street ?? '',
              /*letter: this.order.client_letter ?? '',*/
              house_number: this.order.client_house_number ?? '',
              postal_code: this.order.client_postal_code ?? '',
              city: this.order.client_city ?? '',
              description: this.order.client_description ?? '',
            },

            error_list: {},

            success: false,
            copy: this.order.link ? 'https://order.goodzrun.nl/order_'+ this.order.link : '',
            copied: false,

            saving: false
        }
    },

    watch: {
        selected_establishment(val) {
            let info = this.establishments.find(elem => elem.id === val);

            this.establishment_info = true;

            this.fields.street = info.street;
            this.fields.letter = info.letter;
            this.fields.house_number = info.house_number;
            this.fields.postal_code = info.postal_code;
            this.fields.city = info.city;
        },

        client_name(val) {
            axios.post('/api/v1/insert-order/order/getClientName', {
                name: val
            }).then((r)=>{
               this.find__client_name = r.data;
            });
        }

    },

    computed: {
      client_name() {
          return this.client.name;
      }
    },

    mounted() {
        if(this.establishments && !this.order) {
            let info = this.establishments.find(elem => elem.id === this.establishments[0].id);

            this.establishment_info = true;

            this.fields.street = info.street;
            this.fields.letter = info.letter;
            this.fields.house_number = info.house_number;
            this.fields.postal_code = info.postal_code;
            this.fields.city = info.city;
        }
    },

    methods: {
        setClientName(client) {
            if(client.client_name == this.client.name) {
                this.find__client_name = false;
            } else {
                this.client.name = client.client_name;
                this.client.street = client.client_street;
                this.client.house_number = client.client_house_number;
                this.client.postal_code = client.client_postal_code;
                this.client.city = client.client_city;
                this.client.phone = client.client_phone;
                this.find__client_name = false;
            }

            console.log(this.client.name);
        },
        saveOrder() {
            !this.order ? this.saving = true : null;
            axios.post('/api/v1/insert-order/order/save', {
                id: this.order.id ?? false,
                establishment: this.selected_establishment,
                link: this.order.link ?? this.link,
                order: this.fields,
                client: this.client,
                status_id: 1
            })
                .then(r => {
                    this.error_list = false;


                    if(this.order) {
                        this.$toasted.show('Order successfully edited!', {
                            theme: "bubble",
                            position: "bottom-center",
                            duration : 3000
                        })
                    } else {
                        setTimeout(function(){
                            window.location.href = '/insert-order/order'
                        }, 300);
                    }
                })
                .catch(e => {
                    this.error_list = e.response.data.errors
                });
        },
        copyLink() {
            try {
                const textarea = document.createElement('textarea');
                textarea.value = this.copy;

                document.body.appendChild(textarea);

                textarea.select();

                document.execCommand('copy');
                document.body.removeChild(textarea);
                this.copied = true;
            }
            catch(e) {
                console.log(e);
            }
        }
    }
}
</script>
