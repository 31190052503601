<template>
    <div class="map-content">
        <div class="sidenav sidenav--map">
            <div class="sidenav__header">
                ACTIES
                <button class="sidenav__header-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>

            <div class="sidenav__body sidenav__body--map">

                <div v-if="showRestaurantList" class="sidenav__body-restaurants">
                    <div class="sidenav__body-restaurants__list">
                        <div class="timeline-controls timeline-controls--restaurants">
                            <draggable
                                :list="showRestaurantsArray"
                                :disabled="!enabled"
                                class="list-group"
                                ghost-class="ghost"
                                :move="checkMove"
                                @start="dragging = true"
                                @end="dragging = false"
                            >
                                <div
                                    class="timeline-controls__item draggable"
                                    v-for="element in showRestaurantsArray"
                                    :key="element.name"
                                >
                                    <div class="timeline-controls__item-icon">
                                        <svg x="0px" y="0px" viewBox="0 0 6.6 11.09">
                                            <g>
                                                <g>
                                                    <path d="M2.88,0c-0.1,0-0.19,0.08-0.2,0.18l-0.34,2.7c-0.01,0.1-0.1,0.18-0.2,0.18H2.12c-0.11,0-0.2-0.08-0.2-0.19L1.75,0.19
                                                                        C1.74,0.08,1.65,0,1.54,0h0c-0.11,0-0.2,0.08-0.2,0.19L1.17,2.87c-0.01,0.11-0.1,0.19-0.2,0.19H0.95c-0.1,0-0.19-0.08-0.2-0.18
                                                                        l-0.34-2.7C0.39,0.08,0.31,0,0.2,0C0.09,0,0,0.09,0,0.2v2.85c0,0.63,0.39,1.2,0.98,1.42c0.08,0.03,0.13,0.11,0.13,0.2l-0.3,5.64
                                                                        c-0.03,0.4,0.27,0.75,0.68,0.78s0.75-0.27,0.78-0.67c0-0.04,0-0.07,0-0.11l-0.3-5.64c0-0.09,0.05-0.17,0.13-0.2
                                                                        c0.59-0.23,0.98-0.79,0.98-1.42V0.2C3.08,0.09,2.99,0,2.88,0z M6.28,0.12C6.28,0.05,6.22,0,6.16,0c-0.11,0-0.57,0.17-1.2,1.63
                                                                        C4.32,3.09,4.2,4.45,4.36,5c0.15,0.5,0.59,0.46,1.1,0.47c0.08,0,0.15,0.07,0.14,0.16l-0.46,4.68c-0.03,0.4,0.27,0.75,0.68,0.78
                                                                        c0.4,0.03,0.75-0.27,0.78-0.67c0-0.04,0-0.07,0-0.11L6.28,0.12z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="timeline-controls__item-value">
                                        <div class="timeline-controls__item-value-address">
                                            <div class="timeline-item__address">
                                                {{ element.name }}<span>{{ element.address }}</span>
                                            </div>
                                            <div class="timeline-item__reference">
                                                <span>{{ element.reference }}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </draggable>
                        </div>
                    </div>
                    <div class="sidenav__body-restaurants__controls">
                        <button @click="showRestaurantList = false" class="sidenav__body-btn sidenav__body-btn--white">
                            Cancel
                        </button>
                        <button @click="cancel" class="sidenav__body-btn sidenav__body-btn--black">Ok</button>
                    </div>
                </div>

                <div v-if="! selected_courier_id" class="automation">
                    <div class="form-group form-group--checkbox form-group--checkbox-switcher">
                        <label style="color: #000 !important;" class="form-label">Automation: </label>
                        <switches onclick="return confirm('Are you sure?')" type-bold="true" theme="bootstrap" v-model="automationSwitcher" color="warning"></switches>
                    </div>

                </div>

                <div v-if="!selected_courier_id" class="sidenav__body-couriers">
                    <div class="empty" role="alert" v-if="!courierList || courierList.length == 0">No couriers yet!
                    </div>
                    <div v-else v-for="courier of courierList" @click="setCourierId(courier.info.id)"
                         :key="courier.info.id"
                         :class="`sidenav__body-courier sidenav__body-courier--`+courier.type">
                        {{ courier.info.name }} &nbsp; <span style="color: #ff6454" v-if="courier.free_in">{{ courier.free_in }}</span>
                    </div>
                </div>

                <div v-else-if="selected_courier_profile" class="sidenav__body-points">
                    <div v-if="! isOnline" class="offline" role="alert">
                        Courier are offline!
                    </div>

                    <div v-if="selected_courier_profile" class="sidenav__body-controls">
                        <button style="max-width: 200px !important;" v-if="selected_courier_id" @click="showRestaurants"
                                class="sidenav__body-btn sidenav__body-btn--black mb-3">Send to courier
                        </button>
                    </div>

                    <div class="empty" role="alert" v-if="selected_courier_profile.pointList.length == 0">No points
                        yet
                    </div>

                    <div v-else>
                        <draggable
                            :list="selected_courier_profile.pointList"
                            :disabled="!enabled"
                            class="list-group"
                            ghost-class="ghost"
                            :add="addSort"
                            :replace="replaceSort"
                            :move="checkMoveSort"
                            @start="dragging = true"
                            @end="dragging = false"
                        >
                            <div
                                @mouseleave="addressHovered = false"
                                @mouseover="addressHovered = point.info.id"
                                v-for="(point, i) of selected_courier_profile.pointList"
                                v-if="point.isPoint"
                                :key="i"
                                class="sidenav__body-point"
                            >
                                <img :src="`images/map_`+point.imageStatus+`.svg`"/>
                                <div class="sidenav__body-point--address">
                                    <div>From: {{ point.from }}</div>
                                    <strong>{{ point.address }}</strong>

                                </div>
                                <button v-if="!point.isPoint" @click="addPointToCourier(point)"
                                        class="sidenav__body-point--btn">Add
                                </button>
                                <div v-else>
                                    <button @click="removePoint(point)"
                                            class="sidenav__body-point--btn">Remove
                                    </button>
                                </div>

                            </div>
                        </draggable>

                        <div
                            @mouseleave="addressHovered = false"
                            @mouseover="addressHovered = point.info.id"
                            v-for="(point, i) of selected_courier_profile.pointList"
                            v-if="!point.isPoint"
                            :key="i"
                            class="sidenav__body-point"
                            :class="{'moreThan80' : point.info.price >= 80}"
                        >
                            <img :src="`images/map_`+point.imageStatus+`.svg`"/>
                            <div class="sidenav__body-point--address">
                                <div>From: {{ point.from }}</div>
                                <strong>{{ point.address }}</strong>

                                <!--asap-->
                                <div v-if="!point.asap" style="color: #00aeef;">{{ point.time_at }}</div>
                                <div style="font-size: 14px;" v-else><img width="10" src="images/bike.svg" alt="ASAP"></div>
                                <!--asap-->

                            </div>
                            <button v-if="!point.isPoint" @click="addPointToCourier(point)"
                                    class="sidenav__body-point--btn">Add
                            </button>
                            <div v-else>
                                <button @click="removePoint(point)"
                                        class="sidenav__body-point--btn">Remove
                                </button>
                            </div>

                        </div>
                    </div>
                    <!--                    <div class="sidenav__body-point">
                                            <img src="images/map_2.svg" />
                                            <div class="sidenav__body-point&#45;&#45;address">
                                                Saturnstraat 16, 1824AC
                                            </div>
                                            <button class="sidenav__body-point&#45;&#45;btn">Add</button>
                                        </div>-->
                </div>
            </div>
        </div>
        <div class="container-fluid">

            <!-- <div class="row">
                <div class="col-lg-5 col-md-4 col-sm-6 col-12 content__header content__header--map">
                    <div v-if="selected_courier_profile" class="content__header-map">
                        <div class="content__header-map-photo">
                            <img :src="selected_courier_profile.photo" />
                        </div>
                        <div class="content__header-map-details">
                            <div class="content__header-map-details--name">{{ selected_courier_profile.user_info.name }}</div>
                            <div class="content__header-map-details--phone">061 123 4567</div>
                            <div class="content__header-map-details--status" :class="`content__header-map-details--status-`+selected_courier_profile.type">
                                {{selected_courier_profile.type}}
                                <svg x="0px" y="0px" viewBox="0 0 17.01 14.52">
                                    <g>
                                        <g>
                                            <path d="M10.38,2.97c0.69,0,1.25-0.56,1.25-1.26c0-0.69-0.56-1.26-1.25-1.26c-0.69,0-1.25,0.56-1.25,1.26
                                                            C9.13,2.41,9.69,2.97,10.38,2.97z M13.4,7.26c-1.99,0-3.6,1.63-3.6,3.63c0,2.01,1.61,3.63,3.6,3.63c1.99,0,3.6-1.63,3.6-3.63
                                                            C17.01,8.88,15.39,7.26,13.4,7.26z M3.6,7.26C1.61,7.26,0,8.88,0,10.89c0,2.01,1.61,3.63,3.6,3.63c1.99,0,3.6-1.63,3.6-3.63
                                                            C7.2,8.88,5.59,7.26,3.6,7.26z M10.09,5.86h0.48c0.12,0,0.24,0,0.36,0h1.45c0.33,0,0.6-0.27,0.6-0.61c0-0.33-0.27-0.61-0.6-0.61
                                                            h-2.1c-0.03-0.03-0.05-0.06-0.08-0.11C9.89,3.98,9.58,3.4,9.2,2.88C8.6,2.07,7.94,1.97,7.28,2.56C6.47,3.31,4.74,5.12,4.66,5.25
                                                            C4.41,5.68,4.42,6.12,4.7,6.51c0.16,0.23,0.4,0.42,0.64,0.56c0.74,0.42,1.5,0.81,2.26,1.19c0.17,0.09,0.24,0.18,0.25,0.36
                                                            c0,0.03-0.01,0.07-0.01,0.1v1.1c0,0.11,0,0.22,0,0.33v1.49c0,0.41,0.34,0.75,0.75,0.75c0.41,0,0.75-0.34,0.75-0.75v-0.44
                                                            c0-0.08,0-0.17,0-0.25c0-0.13,0-0.27,0-0.4V8.73c0-0.04,0-0.09-0.01-0.13c0-0.26,0-0.52,0-0.78c0-0.34-0.09-0.5-0.4-0.73
                                                            C8.72,6.93,7.48,6.24,7.15,6.05c0.49-0.53,0.96-1.06,1.47-1.61C8.88,4.85,9.1,5.2,9.34,5.53c0.1,0.14,0.27,0.3,0.42,0.31
                                                            c0.07,0,0.15,0.01,0.22,0.01C10.02,5.86,10.05,5.86,10.09,5.86z M3.43,4.84c0.25,0.26,0.67,0.26,0.92,0l2.43-2.45
                                                            c0.26-0.26,0.26-0.67,0-0.93L5.53,0.19c-0.25-0.26-0.67-0.26-0.92,0L2.18,2.65c-0.25,0.26-0.25,0.67,0,0.93L3.43,4.84z"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="offset-lg-5 col-lg-2 offset-md-4 col-md-4 col-sm-6 col-12 content__right">
                    <div class="content__logo">
                        <img src="/images/logo.svg" alt="FoodLyft logo">
                    </div>
                </div>
            </div> -->

            <div class="row content__map">
                <div class="col-12">
                    <div class="content__map-left">
                        <div class="google-map">
                            <div id="map"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import mapStyle from './map_style.json';
import draggable from 'vuedraggable'
import Switches from 'vue-switches';

export default {
    name: "Map",

    props: ['automation', 'location'],

    components: {
        draggable, Switches
    },

    data() {
        return {
            map: false,
            markers: false,
            markersForNewOrders: false,
            setMarkers: false,
            info_window: false,
            info_window_stay: false,
            bounds: false,

            // YO
            showRestaurantList: false,
            dragging: false,
            enabled: true,
            showRestaurantsArray: [],

            // END YO

            orderCoordinates: [],
            status: 0,

            locations: [],

            cluster_options: {
                url: 'images/google-map/cluster.svg',
                height: 50,
                width: 50,
                textColor: '#000000',
                textSize: 16
            },

            addressHovered: false,


            selected_courier_id: false,
            selected_courier_profile: false,
            courierList: false,
            courier: false,

            markerCluster: false,
            markerClusterForNewOrders: false,
            isFirstLoad: true,

            //autoloader
            interval: null,
            //router
            directionsService: false,
            directionsRenderer: false,

            directionsRoutes: [],

            //online checker
            isOnline: true,


            finishArray: [],

            //new orders display with couriers
            newOrdersDisplayWithCouriers: [],

            //automatization
            automationSwitcher: this.automation,
        }
    },

    mounted() {
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: this.location
                ? {lat: this.location.latitude, lng: this.location.longitude}
                : {lat: 52.132633, lng: 5.291266},

            //center: {lat: 52.370216, lng: 4.895168},
            //center: {lat: 52.011578, lng: 4.357068},
            zoom: this.location ? 12 : 9,
            //zoom: 9,
            styles: mapStyle
        })
        this.startTimer()
        /*   this.directionsService = new google.maps.DirectionsService();
           this.directionsRenderer = new google.maps.DirectionsRenderer();*/

    },

    watch: {

        /**
         * наблюдаем за значением автоматизации
         */

        automationSwitcher(value) {
            axios.post('/api/v1/insert-order/courier-map/automation', {
                value
            }).then(r=>{

                this.$toasted.show('Automation ' + (value ? 'on' : 'off'), {
                    theme: "bubble",
                    position: "bottom-center",
                    duration : 3000
                })
            });
        },

        /**
         * выводим новые заказы на карте с курьерами
         * @param array
         */
        newOrdersDisplayWithCouriers(array) {

            /**
             * билдим маркеры
             */

            this.markersForNewOrders = array.map((arr) => {
                var goldenGatePosition = {lat: arr.coordinates.lat, lng: arr.coordinates.lng};
                var marker = new google.maps.Marker({
                    position: goldenGatePosition,
                    map: this.map,
                    marker: arr,
                    title: arr.address,
                    icon: {
                        url: 'images/google-map/' + arr.type + '.svg',
                        anchor: new google.maps.Point(20, 47),
                        scaledSize: new google.maps.Size(30, 37)
                    },
                });

                marker.addListener('mouseover', () => {
                    setTimeout(() => {
                        this.info_window.setContent(`<p>${arr.from}</p><p>${arr.address}</p><p style="color: #00aeef;">${arr.time_at}</p>`);
                        this.info_window.open(this.map, marker);
                    }, 500);
                });

                marker.addListener('mouseout', () => {
                    setTimeout(() => {
                        this.info_window.close(this.map);
                    }, 500);
                });

                return marker;
            });

            this.markerClusterForNewOrders = new MarkerClusterer(this.map, this.markersForNewOrders, {
                gridSize: 100,
                minimumClusterSize: 10,
                styles: [this.cluster_options]
            });


        },

        /**
         * рисуем маршруты
         * @param arr
         */
        directionsRoutes(arr) {
            //if(arr.length > 0) {

            if (this.directionsRenderer != false) {
                this.directionsRenderer.setMap(null);
            }

            if (this.directionsRoutes.length > 0) {
                this.directionsService = new google.maps.DirectionsService();

                this.directionsRenderer = new google.maps.DirectionsRenderer();
                this.directionsRenderer.setMap(this.map);
                this.directionsRenderer.setOptions({suppressMarkers: true});

                // let start = new google.maps.LatLng(52.011578, 4.357068);
                // let end = new google.maps.LatLng(52.011578, 4.357068);
                let start, end;
                if(this.location) {
                    start = new google.maps.LatLng(this.location.latitude, this.location.longitude);
                    end = new google.maps.LatLng(this.location.latitude, this.location.longitude);
                } else {
                    start = new google.maps.LatLng(
                        this.selected_courier_profile.location.latitude,
                        this.selected_courier_profile.location.longitude
                    );
                    end = new google.maps.LatLng(
                        this.selected_courier_profile.location.latitude,
                        this.selected_courier_profile.location.longitude
                    );
                }



                let request = {
                    origin: start,
                    destination: end,
                    waypoints: arr,
                    optimizeWaypoints: true,
                    travelMode: google.maps.TravelMode.BICYCLING
                };

                this.directionsService.route(request, (result, status) => {
                    if (status == google.maps.DirectionsStatus.OK) {
                        this.directionsRenderer.setDirections(result);
                    }
                });


                if (this.selected_courier_id) {
                    this.directionsRenderer.addListener("directions_changed", () => {
                        const a = this.directionsRenderer.getDirections();
                        const myroute = a.routes[0];

                        if (!myroute) {
                            return;
                        }

                        if (myroute.waypoint_order.length > 0) {
                            let same = [];

                            myroute.waypoint_order.forEach((el, index) => {
                                console.log(el);
                                this.selected_courier_profile.pointList.forEach((e, i) => {
                                    if (i == el) {
                                        let obj = {
                                            'pointId': e.info.id,
                                            'durationTime': myroute.legs[index].duration.value,
                                        }
                                        same.push(obj);
                                    }

                                });
                            });

                            //console.log(myroute)

                            /*let same_length = same.length-1;*/


                            axios.post('/api/v1/insert-order/courier-map/getTrueSort', {
                                courierId: this.selected_courier_id,
                                trueSort: same
                            }).then((r) => {
                                axios.post('/api/v1/insert-order/courier-map/courier', {
                                    id: this.selected_courier_id
                                }).then((r) => {
                                    this.selected_courier_profile.pointList = r.data.pointList;
                                });
                            });
                        }
                    });
                }

            }

            // }
        },

        /**
         * отображаем поинты
         */
        locations(val) {
            if (this.locations != false) {

                if (this.selected_courier_profile) {

                    /** добавляем маркер ресторана и курьера(если он выбран) */

                    var holdMarkers = [

                        //restaurant
                        {
                            icon: 'rest',
                            title: 'Restaurant',
                            position: this.location
                                ? {lat: this.location.latitude, lng: this.location.longitude}
                                : {
                                    lat: this.selected_courier_profile.location.latitude,
                                    lng: this.selected_courier_profile.location.longitude
                                }
                        },

                        //courier
                        {
                            icon: this.selected_courier_profile.type,
                            title: 'Courier',
                            position: {
                                lat: this.selected_courier_profile.latitude, lng: this.selected_courier_profile.longitude
                            }
                        }
                    ];

                    holdMarkers.forEach((i) => {
                        new google.maps.Marker({
                            position: i.position,
                            map: this.map,
                            title: i.title,
                            icon: {
                                url: 'images/google-map/'+i.icon+'.svg',
                                anchor: new google.maps.Point(20, 47),
                                scaledSize: new google.maps.Size(30, 37)
                            },
                        });
                    })

                }


                this.directionsRoutes = [];
                this.info_window = new google.maps.InfoWindow({content: ''});
                this.info_window_stay = new google.maps.InfoWindow({content: ''});

                this.markers = this.locations.map((ee) => {
                    //this.map.setCenter(new google.maps.LatLng(ee.coordinates.lat, ee.coordinates.lng));
                    let m = new google.maps.Marker({
                        position: ee.coordinates,
                        marker: ee,
                        map: this.map,
                        icon: {
                            url: 'images/google-map/' + ee.type + '.svg',
                            anchor: new google.maps.Point(20, 47),
                            scaledSize: new google.maps.Size(30, 37)
                        },
                        draggable: false
                    })

                    if (this.selected_courier_id == false) {
                        m.addListener('click', () => {
                            this.selected_courier_id = ee.info.id;
                        });

                        m.addListener('mouseover', () => {
                            setTimeout(() => {
                                this.info_window.setContent(`<p>${ee.info.name}</p>`);
                                this.info_window.open(this.map, m);
                            }, 500);
                        });

                        m.addListener('mouseout', () => {
                            setTimeout(() => {
                                this.info_window.close();
                            }, 500);
                        });
                    } else {

                        let status = ee.type == 'user_marker' ? 2 : 1;

                        if (status == 2) {
                            this.directionsRoutes.push({
                                location: new google.maps.LatLng(ee.coordinates.lat, ee.coordinates.lng),
                                /* location: 123,*/
                                stopover: true,
                            })
                        }

                        m.addListener('click', () => {
                            let status = ee.type == 'user_marker' ? 2 : 1;

                            if(status == 1) {
                                this.addPointToCourier(ee)
                            } else {
                                this.removePoint(ee)
                            }

                        });

                        m.addListener('mouseover', () => {
                            setTimeout(() => {
                                let markerContent = '';
                                let status = ee.type == 'user_marker' ? 2 : 1;
                                this.info_window.setContent(`<p>${ee.from}</p><p>${ee.address}</p>`);
                                this.info_window.open(this.map, m);
                            }, 500);
                        });

                         m.addListener('mouseout', () => {
                            setTimeout(() => {
                                this.info_window.close();
                            }, 500);
                        });
                    }

                    return m;
                })

                this.markerCluster = new MarkerClusterer(this.map, this.markers, {
                    gridSize: 100,
                    minimumClusterSize: 10,
                    styles: [this.cluster_options]
                });

            }
        },

        selected_courier_id(val) {

            let courierId = val;
            if (courierId != false) {
                this.locations = false;
                this.newOrdersDisplayWithCouriers = [];
                this.markerCluster.clearMarkers();

                this.getCourierInfo(courierId);


                if(this.automationSwitcher) {
                    this.checkWhenAutomation();
                }

            }
        },

        addressHovered() {
            this.markerCluster.clearMarkers();

            this.markers = this.locations.map((ee) => {
                let m = new google.maps.Marker({
                    position: ee.coordinates,
                    marker: ee,
                    map: this.map,
                    icon: {
                        url: this.addressHovered == ee.info.id ? 'images/google-map/map_home.svg' : 'images/google-map/' + ee.type + '.svg',
                        anchor: new google.maps.Point(20, 47),
                        scaledSize: new google.maps.Size(30, 37)
                    },
                    draggable: false
                })

                if (this.selected_courier_id && this.selected_courier_profile) {
                    /* m.addListener('click', () => {
                        let markerContent = '';
                        let status = ee.type == 'user_marker' ? 2 : 1;
                        markerContent += `<button onclick='addPointFromMap(${ee.info.id}, ${status});'>${ee.type == 'user_marker' ? 'Delete point' : 'Add point'}</button>`;
                        this.info_window.setContent(`<p>${ee.address}</p>` + markerContent);
                        this.info_window.open(this.map, m);
                    }); */
                    m.addListener('click', () => {
                        let status = ee.type == 'user_marker' ? 2 : 1;
                        if(status == 1) {
                            this.addPointToCourier(ee)
                        } else {
                            this.removePoint(ee)
                        }
                    });

                    m.addListener('mouseover', () => {
                        setTimeout(() => {
                            let markerContent = '';
                            let status = ee.type == 'user_marker' ? 2 : 1;
                            this.info_window.setContent(`<p>${ee.from}</p><p>${ee.address}</p>`);
                            this.info_window.open(this.map, m);
                        }, 500);

                    });

                    m.addListener('mouseout', () => {
                        setTimeout(() => {
                            this.info_window.close();
                        }, 500);
                    });
                }

                return m;
            })

            /*this.markerCluster = new MarkerClusterer(this.map, this.markers, {
                    styles: [this.cluster_options]
                });*/
            this.markerCluster = new MarkerClusterer(this.map, this.markers, {
                gridSize: 100,
                minimumClusterSize: 10,
                styles: [this.cluster_options]
            });

        },
    },

    beforeDestroy() {
        this.stopTimer()
    },

    methods: {

        checkWhenAutomation() {
            setTimeout(() => {
                if(this.selected_courier_profile.type == 'free') {
                    axios.post('/api/v1/insert-order/courier-map/checkWhenAutomation', {
                        courierId: this.selected_courier_id,
                    }).then((r) => {
                        console.log(r.data)
                        this.getCourierInfo(this.selected_courier_id);
                    });
                }
            }, 1000);
        },

        stopTimer() {
            if (this.interval) {
                window.clearInterval(this.interval)
            }
        },
        startTimer() {
            this.stopTimer()

            this.interval = window.setInterval(() => {
                if (!this.selected_courier_id && !this.selected_courier_profile) {
                    if (this.markerCluster) {
                        this.markerCluster.clearMarkers();
                    }

                    this.markerClusterForNewOrders.clearMarkers();
                    this.getCouriers()
                } else {
                    this.getCourierInfo(this.selected_courier_id, true);
                }
            }, 30000)
        },

        setMapOnAll() {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
        },


        add: function () {
            this.showRestaurantsArray.push({name: "Juan " + id, id: id++});
        },
        replace: function () {
            this.showRestaurantsArray = [{name: "Edgard", id: id++}];
        },
        checkMove: function (e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
            console.log('Array', this.showRestaurantsArray);
        },

        addSort: function () {
            this.selected_courier_profile.pointList.push({name: "Juan " + id, id: id++});
        },
        replaceSort: function () {
            this.selected_courier_profile.pointList = [{name: "Edgard", id: id++}];
        },

        checkMoveSort: function (e) {
            //console.log(e)
            //window.console.log("Future index: " + e.draggedContext.futureIndex);
            let arr = this.selected_courier_profile.pointList;


            setTimeout(() => {
                axios.post('/api/v1/insert-order/courier-map/getTrueSortManually', {
                    courierId: this.selected_courier_id,
                    trueSort: arr
                }).then((r) => {
                    console.log(r)
                });
            }, 1000);
        },


        setCourierId(courierId) {
            this.selected_courier_id = courierId;
        },

        getCouriers() {
            axios.post('/api/v1/insert-order/courier-map/list').then((r) => {
                this.locations = this.courierList = r.data.couriers;
                this.newOrdersDisplayWithCouriers = r.data.new_orders;
            });
        },

        getCourierInfo(courier, onlyProfile = false) {
            axios.post('/api/v1/insert-order/courier-map/courier', {
                id: courier
            }).then((r) => {

                this.selected_courier_profile = r.data;
                console.log(r.data)
                if (!r.data.isOnline && this.isOnline) {
                    this.isOnline = false
                } else if (r.data.isOnline && !this.isOnline) {
                    this.isOnline = true;
                }
                if (!onlyProfile) {
                    this.locations = r.data.pointList;
                }

            });
        },

        showRestaurants() {
            this.showRestaurantList = true

            axios.post('/api/v1/insert-order/courier-map/getRestaurantList', {
                courierId: this.selected_courier_id
            }).then((r) => {
                this.showRestaurantsArray = r.data;
            });
        },

        cancel() {
            //this.showRestaurantsArray = [];

            axios.post('/api/v1/insert-order/courier-map/setTrueRestaurantSort', {
                courierId: this.selected_courier_id,
                data: this.showRestaurantsArray
            }).then((r) => {
                if (r.data.isSuccess) {
                    axios.post('/api/v1/insert-order/courier-map/sendPushNotify', {
                        courierId: this.selected_courier_id
                    })
                        .then(r => {
                            location.href = '/map'
                        });
                }

            });
        },

        addPointToCourier(point, onlyId = false) {

            this.finishArray.push(point);

            axios.post('/api/v1/insert-order/courier-map/addPoint', {
                id: onlyId ? point : point.info.id,
                courierId: this.selected_courier_id
            }).then((r) => {
                axios.post('/api/v1/insert-order/courier-map/courier', {
                    id: this.selected_courier_id
                }).then((r) => {
                    this.selected_courier_profile = r.data;
                    this.locations = r.data.pointList;
                    this.info_window.close();

                    this.$toasted.show('Point added!', {
                        theme: "bubble",
                        position: "bottom-center",
                        duration: 3000
                    })
                });
            });
        },


        removePoint(point, onlyId = false) {


            axios.post('/api/v1/insert-order/courier-map/removePoint', {
                id: onlyId ? point : point.info.id,
                courierId: this.selected_courier_id
            }).then((r) => {
                if (r.data.isSuccess) {
                    axios.post('/api/v1/insert-order/courier-map/courier', {
                        id: this.selected_courier_id
                    }).then((r) => {
                        this.selected_courier_profile = r.data;
                        this.locations = r.data.pointList;
                        this.info_window.close();

                        this.$toasted.show('Point removed!', {
                            theme: "bubble",
                            position: "bottom-center",
                            duration: 3000
                        })
                    });
                }
            });
        },
    },

    created() {
        this.getCouriers();

        this.directionsService = new google.maps.DirectionsService();

        window.addPointFromMap = (point, action) => {
            if (action == 1) {
                this.addPointToCourier(point, true);
            } else if (action == 2) {
                this.removePoint(point, true);
            }
        }
    }

}
</script>

<style scoped>

.moreThan80 {
    border: 1px solid #66afc5;
}

.automation {
    background-color: rgb(241, 178, 178);
    padding-left: 25px;
}

.offline {
    padding: 10px;
    background-color: rgb(187, 76, 76);
    border: 1px solid rgb(204, 50, 50);
    color: #fff;
    text-align: center;
}

.empty {
    padding: 10px;
    background-color: rgb(231, 233, 102);
    border: 1px solid rgb(205, 207, 74);
    color: rgb(46, 46, 46);
    text-align: center;
}

#map {
    width: 100%;
    height: 100%;
}

.courier-status {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 12px;

}

.status-free {
    background-color: green;
}

.status-busy {
    background-color: red;
}

.sidenav__body-point--btn:disabled {
    background-color: #3333;
}
</style>
