


<template>
    <div class="container-fluid">
        <div class="form-border">
            <div class="col-12 form-wrapper col-md-12 col-lg-8 col-sm-12">
                <div class="row ">
                    <!--input naam-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="name" v-model="fields.name" class="form-input" placeholder=" ">
                            <label for="name" class="form-label">Naam <span class="required">*</span></label>
                            <span v-if="error_list.name" class="error-label">{{ error_list.name[0] }}</span>
                        </div>
                    </div>
                    <!--input naam-->
                    <!--input straat-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="street" v-model="fields.street" class="form-input" placeholder=" ">
                            <label for="street" class="form-label">Straat <span class="required">*</span></label>
                            <span v-if="error_list.street" class="error-label">{{ error_list.street[0] }}</span>
                        </div>
                    </div>
                    <!--input-->
                    <!--input letter-->
                    <div class="col-4 col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input id="letter" v-model="fields.letter" class="form-input" placeholder=" ">
                            <label for="letter" class="form-label">Letter</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input huisnummer-->
                    <div class="col-8 col-lg-8 col-sm-8">
                        <div class="form-group">
                            <input id="house_number" v-model="fields.house_number" class="form-input" placeholder=" ">
                            <label for="house_number" class="form-label">Huisnummer <span class="required">*</span></label>
                            <span v-if="error_list.house_number" class="error-label">{{ error_list.house_number[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div  class="col-12 col-lg-12 col-sm-12">
                        <div class="form-group form-group--select">
                            <select name="select" v-model="fields.location_id" id="location_id" class="form-select">
                                <option value="" disabled>-- Kiezen --</option>
                                <option v-for="(loc, index) in locations" :key="index" :value="loc.id">
                                    {{ loc.name }}
                                </option>
                            </select>
                            <label for="transport_id" class="form-label">Ligging <span class="required">*</span></label>
                            <span v-if="error_list.location_id" class="error-label">{{  error_list.location_id[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input postcode-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="postal_code" v-model="fields.postal_code" class="form-input" placeholder=" ">
                            <label for="postal_code" class="form-label">Postcode <span class="required">*</span></label>
                            <span v-if="error_list.postal_code" class="error-label">{{ error_list.postal_code[0] }}</span>
                        </div>
                    </div>
                    <!--input-->
                    <!--input stad-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="city" v-model="fields.city" class="form-input" placeholder=" ">
                            <label for="city" class="form-label">Stad <span class="required">*</span></label>
                            <span v-if="error_list.city" class="error-label">{{ error_list.city[0] }}</span>
                        </div>
                    </div>
                    <!--input-->
                    <!--input telefoonnummer-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="phone" v-model="fields.phone" class="form-input" placeholder=" ">
                            <label for="phone" class="form-label">Telefoonnummer</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input telefoonnummer voor sms-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="second_phone" v-model="fields.second_phone" class="form-input" placeholder=" ">
                            <label for="second_phone" class="form-label">Telefoonnummer voor sms</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input e-mailadres-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="email" type="email" v-model="fields.email" class="form-input" placeholder=" ">
                            <label for="email" class="form-label">E-mailadres</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input website-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="website" type="text" v-model="fields.website" class="form-input" placeholder=" ">
                            <label for="website" class="form-label">Website</label>
                        </div>
                    </div>
                    <!--input-->

                    <!--select-->
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="form-group form-group--select">
                            <select name="select" v-model="fields.invoice_period" id="establishment" class="form-select">
                                <option value="1">Een keer per week</option>
                                <option value="2">Eens per maand</option>
                            </select>
                            <label for="establishment" class="form-label">Factuurperiode</label>
                            <span v-if="error_list.invoice_period" class="error-label">{{  error_list.invoice_period[0] }}</span>
                        </div>
                    </div>
                    <!--select-->

                    <!--input website-->
                    <div class="col-3 col-lg-3 col-sm-3">
                        <div class="form-group">
                            <input id="module_price" type="text" v-model="fields.module_price" class="form-input" placeholder=" ">
                            <label for="module_price" class="form-label">Price of one module, &euro;</label>
                            <span v-if="error_list.module_price" class="error-label">{{  error_list.module_price[0] }}</span>
                        </div>
                    </div>
                    <!--input-->


                    <!--input actief-->
                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="form-group form-group--checkbox form-group--checkbox-switcher">
                            <label class="form-label">Actief</label>
                            <switches onclick="return confirm('Are you sure?')" type-bold="true" theme="bootstrap" color="warning" v-model="fields.active"></switches>
                        </div>
                    </div>
                    <!--input-->

                    <!--input lightspeed api key-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="lightspeed_api_key" type="text" v-model="fields.lightspeed.api_key" class="form-input" placeholder=" ">
                            <label for="lightspeed_api_key" class="form-label">Lightspeed Api Key</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input lightspeed webhook secret-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="lightspeed_webhook_secret" type="text" v-model="fields.lightspeed.webhook_secret" class="form-input" placeholder=" ">
                            <label for="lightspeed_webhook_secret" class="form-label">Lightspeed Webhook Secret</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input cashdesk user-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="cashdesk_user" type="text" v-model="fields.cashdesk.user" class="form-input" placeholder=" ">
                            <label for="cashdesk_user" class="form-label">Cashdesk Gebruikersnaam</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input cashdesk password-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="cashdesk_password" type="text" v-model="fields.cashdesk.password" class="form-input" placeholder=" ">
                            <label for="cashdesk_password" class="form-label">Cashdesk Wachtwoord</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input thuisbezorgd user-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="thuisbezorgd_user" type="text" v-model="fields.thuisbezorgd.user" class="form-input" placeholder=" ">
                            <label for="thuisbezorgd_user" class="form-label">Thuisbezorgd Gebruikersnaam</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input thuisbezorgd password-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="thuisbezorgd_password" type="text" v-model="fields.thuisbezorgd.password" class="form-input" placeholder=" ">
                            <label for="thuisbezorgd_password" class="form-label">Thuisbezorgd Wachtwoord</label>
                        </div>
                    </div>
                    <!--input-->

                    <!--input Sitedish id-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="sitedish_id" type="text" v-model="fields.sitedish.id" class="form-input" placeholder=" ">
                            <label for="sitedish_id" class="form-label">Sitedish Client ID</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input Sitedish id-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="sitedish_secret" type="text" v-model="fields.sitedish.secret" class="form-input" placeholder=" ">
                            <label for="sitedish_secret" class="form-label">Sitedish secret key</label>
                        </div>
                    </div>
                    <!--input-->

                    <!--input foodticket user-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="foodticket_user" type="text" v-model="fields.foodticket.user" class="form-input" placeholder=" ">
                            <label for="foodticket_user" class="form-label">Foodticket Gebruikersnaam</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input foodticket password-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="foodticket_password" type="text" v-model="fields.foodticket.password" class="form-input" placeholder=" ">
                            <label for="foodticket_password" class="form-label">Foodticket Wachtwoord</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input pos4 user-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="pos4_user" type="text" v-model="fields.posfour.user" class="form-input" placeholder=" ">
                            <label for="pos4_user" class="form-label">Pos4 Gebruikersnaam</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input pos4 password-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="pos4_password" type="text" v-model="fields.posfour.password" class="form-input" placeholder=" ">
                            <label for="pos4_password" class="form-label">Pos4 Wachtwoord</label>
                        </div>
                    </div>
                    <!--input-->
                    <!--input pos4 url-->
                    <div class="col-12 col-lg-6 col-sm-12">
                        <div class="form-group">
                            <input id="pos4_url" type="text" v-model="fields.posfour.url" class="form-input" placeholder=" ">
                            <label for="pos4_url" class="form-label">Pos4 Alternative Url</label>
                        </div>
                    </div>
                    <!--input-->
                </div>
            </div>

            <div class="col-12 form-wrapper col-md-12 col-lg-8 col-sm-12">
                <div class="row">
                    <div class="col-6 col-lg-3 col-sm-6">
                        <div class="form-group">
                            <input id="standard_cooking" type="number" v-model="fields.standard_cooking" class="form-input" placeholder=" ">
                            <label for="standard_cooking" class="form-label">Standard cooking time</label>
                        </div>
                    </div>

                    <div class="col-6 col-lg-3 col-sm-6">
                        <div class="form-group">
                            <input id="busy_cooking" type="number" v-model="fields.busy_cooking" class="form-input" placeholder=" ">
                            <label for="busy_cooking" class="form-label">Busy cooking time</label>
                        </div>
                    </div>
                </div>
            </div>

<!--            <div class="col-12 col-lg-12 col-sm-12">-->
<!--                <div class="form-group form-group&#45;&#45;checkbox form-group&#45;&#45;checkbox-switcher">-->
<!--                    <label class="form-label">Actief</label>-->
<!--                    <switches onclick="return confirm('Are you sure?')" type-bold="true" theme="bootstrap" color="warning" v-model="report_period"></switches>-->
<!--                </div>-->
<!--            </div>-->
        <div class="col-12 form-wrapper col-md-12 col-lg-4 col-sm-12 establishment-table-wrapper">
            <div class="row">
                <table class="establishment-table establishment-table--create medium" cellspacing="2" border="1" cellpadding="5">
                    <tr>
                        <td class="center"></td>
                        <td class="center bold"> < 45 Euro</td>
                        <td class="center bold">45 tot 79,99 Euro</td>
                        <td class="center bold">80+ Euro</td>
                    </tr>
                    <tr>
                        <td class="bold"><981 Orders</td>
                        <td><input type="number" step="0.01" v-model="to300['before45']"/></td>
                        <td><input type="number" step="0.01" v-model="to300['after45']"/></td>
                        <td><input type="number" step="0.01" v-model="to300['after80']"/></td>
                    </tr>
                    <tr>
                        <td class="bold">981-2796 Orders</td>
                        <td><input type="number" step="0.01" v-model="to699['before45']"/></td>
                        <td><input type="number" step="0.01" v-model="to699['after45']"/></td>
                        <td><input type="number" step="0.01" v-model="to699['after80']"/></td>
                    </tr>
                    <tr>
                        <td class="bold">2797+ Orders</td>
                        <td><input type="number" step="0.01" v-model="plus700['before45']"/></td>
                        <td><input type="number" step="0.01" v-model="plus700['after45']"/></td>
                        <td><input type="number" step="0.01" v-model="plus700['after80']"/></td>
                    </tr>
                </table>
            </div>
        </div>
        </div>
        <div class="">
            <div class="form-buttons">
                <button @click="saveEstablishment(false)" class="btn-form-submit btn-form-submit--blue">Houden</button>
                <button v-if="save" @click="saveEstablishment(true)" class="btn-form-submit btn-form-submit--green">Opslaan en gebruiker aanmaken</button>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import Switches from 'vue-switches';

export default {
    name: "Create",

    props: {
        establishment: false,
        save: Boolean,
        report_settings: false,
        locations: Array
    },

    components: { Multiselect, Switches },

    data() {
        return {
            //report_period: this.report_settings ? this.report_settings['period'] : true,
            to300: {
                before45: this.report_settings ? this.report_settings['to300_before45'] : 0,
                after45: this.report_settings ? this.report_settings['to300_after45'] : 0,
                after80: this.report_settings ? this.report_settings['to300_after80'] : 0,
            },
            to699: {
                before45: this.report_settings ? this.report_settings['to699_before45'] : 0,
                after45: this.report_settings ? this.report_settings['to699_after45'] : 0,
                after80: this.report_settings ? this.report_settings['to699_after80'] : 0,
            },
            plus700: {
                before45: this.report_settings ? this.report_settings['700plus_before45'] : 0,
                after45: this.report_settings ? this.report_settings['700plus_after45'] : 0,
                after80: this.report_settings ? this.report_settings['700plus_after80'] : 0,
            },

            fields: {
              name: this.establishment.name ?? '',
              location_id: this.establishment.location_id ?? '',
              street: this.establishment.street ?? '',
              letter: this.establishment.letter ?? '',
              invoice_period: this.establishment.invoice_period ?? 1,

              phone: this.establishment.phone ?? '',


              standard_cooking: this.establishment.standard_cooking ?? '',
              busy_cooking: this.establishment.busy_cooking ?? '',

              second_phone: this.establishment.second_phone ?? '',

              email: this.establishment.email ?? '',
              website: this.establishment.website ?? '',
              module_price: this.establishment.module_price ?? '',

              house_number: this.establishment.house_number ?? '',
              postal_code: this.establishment.postal_code ?? '',
              city: this.establishment.city ?? '',
              active: this.establishment.active ?? 1,
              lightspeed: {
                  api_key: this.establishment.lightspeed_api_key ?? '',
                  webhook_secret: this.establishment.lightspeed_webhook_secret ?? ''
              },
              cashdesk: {
                  user: this.establishment.cashdesk_user ?? '',
                  password: this.establishment.cashdesk_password ?? ''
              },
              sitedish: {
                id: this.establishment.sitedish_id ?? '',
                secret: this.establishment.sitedish_secret ?? '',
              },
              thuisbezorgd: {
                  user: this.establishment.thuisbezorgd_user ?? '',
                  password: this.establishment.thuisbezorgd_password ?? '',
              },
              foodticket: {
                  user: this.establishment.foodticket_user ?? '',
                  password: this.establishment.foodticket_password ?? '',
              },
              posfour: {
                  user: this.establishment.posfour_user ?? '',
                  password: this.establishment.posfour_password ?? '',
                  url: this.establishment.posfour_url ?? '',
              }
            },

            error_list: {},

            success: false
        }
    },

    methods: {
        saveEstablishment(redirect = false) {
            axios.post('/api/v1/insert-order/establishment/save', {
                id: this.establishment.id ?? false,
                name: this.fields.name,
                location_id: this.fields.location_id,
                street: this.fields.street,
                letter: this.fields.letter,

                to300: this.to300,
                to699: this.to699,
                plus700: this.plus700,

                phone: this.fields.phone,

                standard_cooking: this.fields.standard_cooking,
                busy_cooking: this.fields.busy_cooking,

                second_phone: this.fields.second_phone,

                email: this.fields.email,
                website: this.fields.website,
                module_price: this.fields.module_price,

                house_number: this.fields.house_number,
                postal_code: this.fields.postal_code,
                invoice_period: this.fields.invoice_period,
                city: this.fields.city,
                active: this.fields.active,
                lightspeed: this.fields.lightspeed,
                sitedish: this.fields.sitedish,
                cashdesk: this.fields.cashdesk,
                thuisbezorgd: this.fields.thuisbezorgd,
                foodticket: this.fields.foodticket,
                posfour: this.fields.posfour,
                redirect: redirect
            })
                .then(r => {
                    this.error_list = false;
                    this.success = true

                    if(this.save) {
                        if(redirect) {
                            window.location.href = '/insert-order/users/create/' + r.data.id
                        } else {
                            setTimeout(function(){
                                window.location.href = '/insert-order/establishments'
                            }, 300);
                        }
                    } else {
                        this.$toasted.show('Restaurant has been changed!', {
                            theme: "bubble",
                            position: "bottom-center",
                            duration : 3000
                        })
                    }
                })
                .catch(e => {
                    this.error_list = e.response.data.errors
                });
        }
    }
}
</script>
