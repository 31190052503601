<template>
    <div class="container-fluid">
        <div class="form-border">
            <div class="col-12 form-wrapper col-md-12 col-lg-8 col-sm-12">
                <div class="row ">


                    <!--select-->
                    <div class="col-12 col-lg-12">
                        <div class="form-group form-group--select">
                            <select v-model="role" class="form-select" id="role">
                                <option value="1">Medewerker</option>
                                <option value="0">Courier</option>
                                <option value="3">Locatie manager</option>
                                <option value="2">Administrator</option>
                            </select>
                            <label class="form-label" for="role">Rol</label>
                        </div>
                    </div>
                    <!--select-->

                    <!--select-->
                    <div class="col-12 col-lg-12">
                        <div class="form-group form-group--checkbox form-group--checkbox-switcher">
                            <label class="form-label">Actief</label>
                            <switches onclick="return confirm('Are you sure?')" type-bold="true" theme="bootstrap"
                                      color="warning" v-model="user.active"></switches>
                        </div>
                    </div>
                    <!--select-->


                    <div v-if="role == 1" class="col-12 col-lg-12">
                        <div class="form-group form-group--select">
                            <select name="select" v-model="value" id="establishment" class="form-select">
                                <option value="" disabled>-- Kiezen --</option>
                                <option v-for="(establishment, index) in options" :key="index"
                                        :value="establishment.id">
                                    {{ establishment.name }}
                                </option>
                            </select>
                            <label for="establishment" class="form-label">Restaurant</label>
                            <span v-if="error_list.establishment" class="error-label">{{
                                    error_list.establishment[0]
                                }}</span>
                        </div>
                    </div>
                    <!--select-->

                    <!--input-->
                    <div  v-if="role == 3 || role == 0" class="col-12 col-lg-12 col-sm-12">
                        <div class="form-group form-group--select">
                            <select name="select" v-model="selected_location" id="location_id" class="form-select">
                                <option value="" disabled>-- Kiezen --</option>
                                <option v-for="(loc, index) in locations" :key="index" :value="loc.id">
                                    {{ loc.name }}
                                </option>
                            </select>
                            <label for="transport_id" class="form-label">Ligging <span class="required">*</span></label>
                            <span v-if="error_list.location_id" class="error-label">{{  error_list.location_id[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="email" v-model="user.email" class="form-input" placeholder=" ">
                            <label for="email" class="form-label">E-mailadres <span class="required">*</span></label>
                            <span v-if="error_list.email" class="error-label">{{ error_list.email[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="login" v-model="user.login" class="form-input" placeholder=" ">
                            <label for="login" class="form-label">Gebruikersnaam <span class="required">*</span></label>
                            <span v-if="error_list.login" class="error-label">{{ error_list.login[0] }}</span>
                        </div>
                    </div>
                    <!--input-->


                    <!--input-->
                    <div v-if="role == 0" class="col-12 col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input id="name" v-model="user.name" class="form-input" type="text" placeholder=" ">
                            <label for="name" class="form-label">Voor-en achternaam</label>
                            <span v-if="error_list.name" class="error-label">{{ error_list.name[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div v-if="role == 0" class="col-3 col-lg-3 col-sm-3">
                        <div class="form-group form-group--select">
                            <select name="select" v-model="selected_transport" id="transport_id" class="form-select">
                                <option value="" disabled>-- Kiezen --</option>
                                <option v-for="(t, index) in transports" :key="index" :value="t.id">
                                    {{ t.name }}
                                </option>
                            </select>
                            <label for="transport_id" class="form-label">Transport</label>
                            <span v-if="error_list.transport_id" class="error-label">{{
                                    error_list.transport_id[0]
                                }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div v-if="role == 0" class="col-3 col-lg-3 col-sm-3">
                        <div class="form-group">
                            <input id="capacity" v-model="user.capacity" class="form-input" type="text" placeholder=" ">
                            <label for="capacity" class="form-label">Сapacity units, &euro;</label>
                            <span v-if="error_list.capacity" class="error-label">{{ error_list.capacity[0] }}</span>
                        </div>
                    </div>
                    <!--input-->


                    <!--input-->
                    <div v-if="role == 0" class="col-12 col-lg-12 col-sm-12">
                        <span style="color: #999999;font-size: 14px;">Foto</span>

                        <div v-if="user_prop && user_prop.avatar != null">
                            <img v-if="!previewImage" width="150" :src="'/storage/'+user_prop.avatar" alt="*">
                        </div>
                        <div>
                            <img v-if="previewImage" width="150" :src="previewImage" alt="*">
                        </div>

                        <div class="form-group">
                            <input accept="image/*" id="avatar" @change="changeImage" class="form-input" type="file"
                                   placeholder="">
                            <span v-if="error_list.avatar" class="error-label">{{ error_list.avatar[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="password" v-model="user.password" class="form-input" type="password"
                                   placeholder=" ">
                            <label for="password" class="form-label">Wachtwoord <span class="required">*</span></label>
                            <span v-if="error_list.password" class="error-label">{{ error_list.password[0] }}</span>
                        </div>
                    </div>
                    <!--input-->

                    <!--input-->
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input id="repeat_password" v-model="user.repeat_password" class="form-input"
                                   type="password" placeholder=" ">
                            <label for="repeat_password" class="form-label">Herhaal wachtwoord <span
                                class="required">*</span></label>
                            <span v-if="error_list.repeat_password" class="error-label">{{
                                    error_list.repeat_password[0]
                                }}</span>
                        </div>
                    </div>
                    <!--input-->
                </div>
            </div>
        </div>

        <div class="">
            <div class="form-buttons">
                <button v-if="!user_prop" @click="createUser" class="btn-form-submit btn-form-submit--blue">Save
                </button>
                <button v-else @click="updateUser" class="btn-form-submit btn-form-submit--green">Update</button>
            </div>
        </div>
    </div>

</template>

<script>
import Multiselect from 'vue-multiselect'
import Switches from 'vue-switches';

export default {
    name: "Create",

    props: {
        selected_establishment: false,
        establishments: Array,
        transports: Array,
        locations: Array,
        user_prop: false
    },


    components: {Multiselect, Switches},

    data() {
        return {
            value: this.user_prop.establishment_id ?? "",
            options: this.establishments,
            selected_transport: this.user_prop.transport_id ?? '',
            selected_location: this.user_prop.location_id ?? '',

            user: {
                login: this.user_prop.login ?? '',
                email: this.user_prop.email ?? '',
                active: this.user_prop.active ?? true,
                password: '',
                avatar: '',
                name: this.user_prop.name ?? '',
                capacity: this.user_prop.capacity ?? '',
                repeat_password: '',
            },
            role: this.user_prop.role ?? 1,
            //role: this.user_prop.role ? (this.user_prop.role == 2 && this.user_prop.is_deputy == true ? 3 : this.user_prop.role) : 1,

            error_list: {},

            previewImage: null,
        }
    },

    watch: {
        role() {
            this.selected_transport = '';
            this.selected_location= '';
            this.capacity = '';
            this.login = '';
            this.login = '';
            this.value = '';
        }
    },

    methods: {
        changeImage(val) {
            this.user.avatar = val.target.files[0];

            const image = val.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
            };
        },

        createUser() {
            const config = {
                headers: {'content-type': 'multipart/form-data'}
            }

            let formData = new FormData();

            formData.append('avatar', this.user.avatar);

            //const establishments = JSON.stringify(this.value && this.role == 1 ? this.value : false);

            formData.append('establishment', this.value);
            formData.append('login', this.user.login);
            formData.append('email', this.user.email);
            formData.append('name', this.user.name);
            formData.append('capacity', this.user.capacity);
            formData.append('transport_id', this.selected_transport);
            formData.append('location_id', this.selected_location);
            formData.append('active', this.user.active);

            formData.append('password', this.user.password);
            formData.append('repeat_password', this.user.repeat_password);
            formData.append('role', this.role);

            axios.post('/api/v1/insert-order/user/save', formData, config)
                .then(r => {
                    this.error_list = false;

                    setTimeout(function () {
                        window.location.href = '/insert-order/users'
                    }, 300);
                })
                .catch(e => {
                    this.error_list = e.response.data.errors
                });
        },

        updateUser() {
            const config = {
                headers: {'content-type': 'multipart/form-data'}
            }

            let formData = new FormData();

            formData.append('avatar', this.user.avatar);

            //const establishments = JSON.stringify(this.value && this.role == 1 ? this.value : false);

            formData.append('id', this.user_prop.id);
            formData.append('establishment', this.value);
            formData.append('login', this.user.login != this.user_prop.login ? this.user.login : false);
            formData.append('email', this.user.email != this.user_prop.email ? this.user.email : false);
            formData.append('name', this.user.name != this.user_prop.name ? this.user.name : false);
            formData.append('capacity', this.user.capacity);
            formData.append('transport_id', this.selected_transport);
            formData.append('location_id', this.selected_location);
            formData.append('active', this.user.active);

            formData.append('password', this.user.password);
            formData.append('repeat_password', this.user.repeat_password);
            formData.append('role', this.role);


            axios.post('/api/v1/insert-order/user/update', formData, config)
                .then(r => {
                    this.error_list = false;
                    this.user.password = '';
                    this.user.repeat_password = '';

                    this.$toasted.show('User has been changed!', {
                        theme: "bubble",
                        position: "bottom-center",
                        duration: 3000
                    })
                })
                .catch(e => {
                    this.error_list = e.response.data.errors
                });
        },
        multiselectOpen() {
            const inputMultiselect = document.querySelector('.multiselect__input');
            inputMultiselect.classList.add('multiselect__input--hide');
            if (this.value.length > 0) {
                document.querySelector('.multiselect__content-wrapper').classList.add('multiselect__content-wrapper--not-null')
            } else {
                document.querySelector('.multiselect__content-wrapper').classList.remove('multiselect__content-wrapper--not-null')
            }

        },
        multiselectClose() {
            const inputMultiselect = document.querySelector('.multiselect__input');
            inputMultiselect.classList.remove('multiselect__input--hide');
        }
    }
}
</script>
